import React, { useEffect, useState, useRef } from 'react'
import LeftSideBar from '../../SideBar/LeftSideBar'
import { Row, Col, Container, ProgressBar, Form } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiCall, channelFeed, chunkUpload } from '../../../../action/loginAction';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
// import { ChannelsData } from './ChannelsData';
import ReactPaginate from 'react-paginate';
import image from '../../../../constant/image';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import './IndivisualChannel.css';
import MediaGrid from './MediaGrid';
import InfiniteScroll from "react-infinite-scroll-component";
import { actionpermission } from '../../../../constant/commonFunctions';
import { MultiSelect } from "react-multi-select-component";
import Multiselect from 'multiselect-react-dropdown';
import { compressionImage } from '../../../../constant/compressImage';
const chunkSize = 1048576 * 17;
const IndivisualChannel = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [channelData, setChannelData] = useState(state.data)
    const email = localStorage.getItem('email');
    const [isUploadText, setIsUploadText] = useState('');
    const [mideaList, setMideaList] = useState([]);
    const [isImageUpload, setIsImageUpload] = useState(false);
    const [isAudioUpload, setIsAudioUpload] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    //feed data
    const [feedDataValue, setFeedDataValue] = useState([]);
    //video uploading
    const [showProgress, setShowProgress] = useState(false)
    const [counter, setCounter] = useState(1)
    const [fileToBeUpload, setFileToBeUpload] = useState({})
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
    const [progress, setProgress] = useState(0)
    const [fileSize, setFileSize] = useState(0)
    const [chunkCount, setChunkCount] = useState(0)
    const [mediaId, setMediaId] = useState(null);
    const [searchUser, setSearchUser] = useState('');
    const progressInstance = <ProgressBar style={{ height: '100%', borderRadius: '1rem' }} animated now={progress} label={`${progress.toFixed(1)}%`} />;
    //pagination details
    const [page, setPage] = useState(1);
    const [totalFeedPage, setTotalFeedPage] = useState({});
    const topRef = useRef(null);
    const [pendingUserPage, setPendingUserPage] = useState(1);
    const [pendingUserPageData, setPendingUserPageData] = useState({});
    const [channelsPedingUser, setChannelsPedingUser] = useState([]);
    const [pendingUserModalShow, setPendingUserModalShow] = React.useState(false);
    const [isMoreData, setIsMoreData] = useState(true);
    const [totalPage, setTotalPage] = useState(0);
    const [isDeletePost, setIsDeletePost] = useState({ postID: '', indexOfPost: '' });
    const [postAttribute, setPostAttribute] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState({});
    const [isSharePost, setIsSharePost] = useState(false)
    const [channelInitial, setChannelInitial] = useState("");
    const [permissions, setPermissions] = useState([])
    const [filterAttribute, setFilterAttribute] = useState([]);
    const [selectedFilterAttribute, setSelectedFilterAttribute] = useState([]);
    const [isEditPost, setIsEditPost] = useState(false);
    const [profileDetails, setPorfileDetails] = useState({});
    const [modalShow, setModalShow] = React.useState(false);
    const initialRender = useRef(true);
    const [internalPermissions , setInternalPermissions] = useState([]);
    const [currentChannelId , setCurrentChannelId] = useState('')
    const [isPostValue, setIsPostValue] = useState('General');
    const [logedinRole, setLogedinRole] = useState('')
    const postValues = ['General','Pinned']
    // get list of channel 
    useEffect(() => {
        getAttributesData(1);
        channelInitials(state.data.channel_name)
        let permissionsArray = actionpermission("Home")
        setPermissions(permissionsArray)
        let internalPermissionsLocal = JSON.parse(localStorage.getItem("role_permission")).internal_permission.ID_View
        setInternalPermissions(internalPermissionsLocal)
        let logedinrole = localStorage.getItem("role")
        setLogedinRole(logedinrole)
    }, [])
    useEffect(() => {
        if (isDeletePost.postID !== '') {
            deletePostInChannel(isDeletePost.postID)
        }
    }, [isDeletePost])
    useEffect(() => {
        if (isSharePost === true) {
            setIsSharePost(false)
            setFeedDataValue([])
            empty();
            setMideaList([])
            setIsDeletePost({ postID: '', indexOfPost: '' })
            setTimeout(() => {
                getChannelData(1);
                getAttributesData(1);
            }, 100);
        }
    }, [isSharePost])
    useEffect(() => {
        if (fileSize > 0) {
            fileUpload(counter);
        }
    }, [fileToBeUpload, progress])
    useEffect(() => {
        if (searchUser.trim().length === 0 && pendingUserModalShow === true) {
            openPendingUserLisModel(channelData.channel_id);
        } else {
            searchUserChannel()
        }
    }, [searchUser])
    useEffect(() => {
        setPage(1)
        getChannelData(1)
        setIsMoreData(false)
        setFeedDataValue([])
    }, [selectedFilterAttribute])
    //get data after edit 
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            if (isEditPost === true) {
                getChannelData(1)
                setIsEditPost(false)
            }
        }
    }, [isEditPost])
    useEffect(() => {
       console.log(page , "page")
    }, [page])
    //empty all data
    const empty = () => {
        setFeedDataValue([]);
        setIsUploadText('');
        setIsImageUpload(false);
        setIsAudioUpload(false);
        setShowProgress(false);
        setIsFileUpload(false);
        setIsPostValue('General')
    }
    // change height 
    const changeHeightTextArea = (id) => {
        setTimeout(() => {
            var textarea = document.querySelector(id);
            textarea.addEventListener("keydown", e => {
                let scroll_height = e.target.scrollHeight;
                textarea.style.height = `${scroll_height}px`;
            });
        }, 10);
    }
    // get channel data
    const getChannelData = async (pg) => {
        let filter_categories = selectedFilterAttribute.map(attr => {
            return attr.value
        })
        var data = {
            channel_id: channelData.channel_id,
            per_page: 10,
            page_number: pg,
            filter_categories,
            preferred_language: 'EN',
        };
        let res = await dispatch(apiCall(data, '/user/channel-feed'));
        if (res.data.message.MSG_CODE === 100341) {
            setFeedDataValue(res.data.data);
            setTotalFeedPage(res.data.pagination)
            setTotalPage(res.data.pagination.total_page);
            if (res.data.pagination.total_page > 1) {
                setIsMoreData(true);
                setPage(pg + 1);
            } else if (res.data.pagination.total_page == 0 || res.data.pagination.total_page == res.data.pagination.page) {
                setIsMoreData(false)
            }
        } else if (res.data.message.MSG_CODE === 100160) {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    // get channel new data
    const getChannelNewData = async () => {
        let filter_categories = selectedFilterAttribute.map(attr => {
            return attr.value
        })
        var data = {
            channel_id: channelData.channel_id,
            per_page: 10,
            page_number: page,
            filter_categories,
            preferred_language: 'EN',
        };
        let res;
        if (isMoreData) {
            res = await dispatch(apiCall(data, '/user/channel-feed'));
            if (res.data.message.MSG_CODE === 100341) {
                setFeedDataValue([...feedDataValue, ...res.data.data]);
                if (page !== totalPage) {
                    setPage(page + 1);
                } else {
                    setIsMoreData(false);
                }
            } else if (res.data.message.MSG_CODE === 100160) {
                Swal.fire({
                    icon: 'error',
                    text: 'Post Update Failure',
                    timer: 3000
                })
            }
        }
    }
    // upload video in chunks
    const fileUpload = (counter) => {
        setCounter(counter + 1);
        if (counter <= chunkCount) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk)
        }
    }
    const uploadChunk = async (chunk) => {
        try {
            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('chunk_write', 1);
            formData.append('filename', fileToBeUpload.name);
            formData.append('path', 'channel');
            formData.append('chunk_index', counter);
            formData.append('total_chunks', chunkCount);
            formData.append('media_id', mediaId);
            let res = await dispatch(chunkUpload(formData));
            if (res.data.message.MSG_CODE === 100581) {
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                setMediaId(res.data.data.media_id)
                if (counter === chunkCount) {
                    await uploadCompleted(res.data.data.media_id);
                } else {
                    var percentage = (counter / chunkCount) * 100;
                    setProgress(percentage);
                }
            } else {
                empty();
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please try again!',
                    timer: 4000
                })
            }

        } catch (error) {
            console.log('error', error)
        }
    }
    const resetChunkProperties = () => {
        setShowProgress(true)
        setProgress(0)
        setCounter(1)
        setBeginingOfTheChunk(0)
        setEndOfTheChunk(chunkSize)
    }
    const getFileContext = (e) => {
        resetChunkProperties();
        const _file = e.target.files[0];
        setFileSize(_file.size)
        const _totalCount = _file.size % chunkSize === 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount)
        setFileToBeUpload(_file)
        e.target.value = null;
    }
    const uploadCompleted = async (val) => {
        let media_id = val;
        const formData = new FormData();
        formData.append('file_upload', 1);
        formData.append('filename', fileToBeUpload.name);
        formData.append('media_id', mediaId === null ? media_id : mediaId);
        const res = await dispatch(chunkUpload(formData));
        if (res.data.message.MSG_CODE === 100591) {
            checkUploadingStatus(media_id)
        }
    }
    const checkUploadingStatus = async (val) => {
        let media_id = val;
        const formData = new FormData();
        formData.append('get_media_details', 1);
        formData.append('filename', fileToBeUpload.name);
        formData.append('media_id', mediaId === null ? media_id : mediaId);
        const res = await dispatch(chunkUpload(formData));

        if (res.data.message.MSG_CODE === 100601) {
            if (res.data.data.upload_status === 'IN PROGRESS') {
                setTimeout(() => {
                    checkUploadingStatus(media_id)
                }, "3000")
            } else if (res.data.data.upload_status === 'SUCCESS') {
                setProgress(100);
                if (mediaId === null) {
                    setMideaList([...mideaList, media_id]);
                } else {
                    setMideaList([...mideaList, mediaId]);
                }
            } else if (res.data.data.upload_status === 'FAILURE') {
                setShowProgress(false)
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please try again!',
                    timer: 4000
                })
            }
        }
    }
    // postchannel post api
    const uploadPostChannel = async (isPost) => {
        if (isUploadText !== '' || isPost === true) {
            var data = {
                post_type: 'channel',
                channel_id: channelData.channel_id,
                is_create: true,
                text: isUploadText === '' ? null : isUploadText,
                media: mideaList,
                category_id: selectedAttribute ? selectedAttribute.category_id : null,
                is_pinned : isPostValue === 'Pinned' ? true : false
            };
            let res = await dispatch(apiCall(data, '/user/post'));
            if (res.data.message.MSG_CODE === 100091) {
                empty();
                setIsImageUpload(false);
                setIsAudioUpload(false);
                setIsFileUpload(false);
                setIsMoreData(false)
                document.getElementById('uploadImageInputDiv').value = ''
                document.getElementById('uploadAudioInputDiv').value = ''
                document.getElementById('uploadFileInputDiv').value = ''
                setMideaList([])
                setPage(1)
                getChannelData(1);
                Swal.fire({
                    icon: 'success',
                    text: 'Post updated successfully',
                    timer: 3000
                })
            } else if (res.data.message.MSG_CODE === 100160 || res.data.message.MSG_CODE === 100010) {
                empty();
                setIsImageUpload(false);
                setIsAudioUpload(false);
                setIsFileUpload(false);
                setIsMoreData(false)
                document.getElementById('uploadImageInputDiv').value = ''
                document.getElementById('uploadAudioInputDiv').value = ''
                document.getElementById('uploadFileInputDiv').value = ''
                Swal.fire({
                    icon: 'error',
                    text: 'Post Update Failure',
                    timer: 3000
                })
                getChannelData(1);
            }else if (res.data.message.MSG_CODE === 101140) {   
                Swal.fire({
                    icon: 'error',
                    text: 'You have reached the maximum number of pinned posts. Please unpin one of your existing posts to pin this new one.',
                    timer: 10000
                })
            } 
        } else if (isUploadText === '' && isPost === undefined) {
            Swal.fire({
                text: "Do you still want to continue with this post without content?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            }).then((result) => {
                if (result.isConfirmed === true && mideaList.length > 0 || isUploadText !== '') {
                    uploadPostChannel(true)
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: 'Please write some text in textbox or upload some media',
                        timer: 3000
                    })
                }
            })
        }
    }
    // upload api
    //upload image

    const handleSingleImageUpload = async (file) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
      
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            // Release the object URL to free up memory
            URL.revokeObjectURL(img.src);
            // Resolve the promise with width and height
            resolve({ width, height });
          };
        });
      };

    const uploadImage = async (files, type) => {
        dispatch({ type: "showLoader" });
        if (isImageUpload === true && type === 'image') {
            mideaList.pop();
        }
        let arrayFile = []
        for (let i = 0; i < files.length; i++) {
            if (files[i].type === 'image/heif' || files[i].type === 'image/HEIF') {
                arrayFile.push(files[i]);
            } else {
                const image = await compressionImage(files[i]);
                let diamentions = await handleSingleImageUpload(files[i]);
                const height = diamentions.height
                const width = diamentions.width
                image.height = height
                image.width = width
                arrayFile.push(image);
            }

        }
        dispatch({ type: "hideLoader" });
        uploadFile(arrayFile, type);
    };
    const uploadFile = async (file, type) => {
        if (isImageUpload === true && type === 'image') {
            mideaList.pop();
        } else if (isAudioUpload === true && type === 'audio') {
            mideaList.pop();
        } else if (isFileUpload === true && type === 'file') {
            mideaList.pop();
        }
        let arrayMediaId = [];
        let isUploadingCompletedSuccessfully = false
        for (let i = 0; i < file.length; i++) {
            if (file[i].size > 15097152) {
                Swal.fire({
                    text: "Upload fle with size less than 15MB",
                    toast: true,
                    position: "top-end",
                    type: "error",
                    showConfirmButton: false,
                    timer: 5000,
                });
                return
            }
            let convertString = await convertBase64(file[i]);
            let base64Array = convertString.split(",");
            let base64 = base64Array[1];
            var data = {
                email: email,
                file_path: "channel",
                file_data: base64,
                file_name: file[i].name,
                width: file[i].width ? file[i].width : null,
                height: file[i].height ? file[i].height : null
            };
            let res = await dispatch(apiCall(data, '/user/upload'));
            if (res.data.message.MSG_CODE === 100231) {
                arrayMediaId.push(res.data.data.media_id)
            } else if (res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
                    timer: 3000
                })
                break
            }
            else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010) {
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please upload again',
                    timer: 3000
                })
                break
            }
            if (i === file.length - 1) {
                isUploadingCompletedSuccessfully = true
            }
        }
        if (isUploadingCompletedSuccessfully === true) {
            if (type === 'image') {
                setIsImageUpload(true)
            } else if (type === 'audio') {
                setIsAudioUpload(true)
            } else if (type === 'file') {
                setIsFileUpload(true);
            }
            setMideaList([...mideaList, ...arrayMediaId]);
        }
    }
    // delete post 
    const deletePostInChannel = async (postID) => {
        var data = {
            post_id: postID,
            is_delete: true,
        };
        let res = await dispatch(apiCall(data, '/user/post'));
        if (res.data.message.MSG_CODE === 100321) {
            getChannelData(1);
            empty()
            setMideaList([])
        } else if (res.data.message.MSG_CODE === 100390 || res.data.message.MSG_CODE === 100170) {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    // base64 conversion
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (() => {
                resolve(fileReader.result);
            });
            fileReader.onerror = ((error) => {
                reject(error)
            });
        });
    };
    // download csv file 
    const downloadCsvList = async (id) => {
        var data = {
            download_channel_members: true,
            channel_id: id
        };
        let res = await dispatch(apiCall(data, '/user/channel-admin'));
        if (res.data.message.MSG_CODE === 100231) {
            downloadFile(res.data.data)
        } else if (res.data.message.MSG_CODE === 100670) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again!',
                timer: 4000
            })
        }
    }
    const downloadFile = (urlData) => {
        var blob = new Blob([urlData], { type: 'text/csv;charset=utf-8;' })
        var link = document.createElement("a");
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", urlData);
        link.setAttribute("download", "exportedFilenmae");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // const url = window.URL.createObjectURL(new Blob([urlData],{type: "text/csv;charset=utf-8"})) 
        // const link = document.createElement('a')
        // link.href = url
        // link.click()
    }
    //pagination
    const handlePageClick = (data) => {
        let currentPage = data.selected + 1;
        setPage(currentPage)
        if(currentChannelId && channelData.channel_type != 'Public'){
            openPendingUserLisModel(currentChannelId , currentPage);
        }
    }
    //search user
    const searchUserChannel = async () => {
        var data = {
            get_pending_users_of_private_channel: true,
            search_data: searchUser,
            per_page: 10,
            page_number: pendingUserPage,
            channel_id: channelData.channel_id
        }
        let res = await dispatch(apiCall(data, '/user/channel-admin'));
        if (res.data.message.MSG_CODE === 100441) {
            setPendingUserPageData(res.data.pagination);
            setChannelsPedingUser(res.data.data);
            
            if (pendingUserModalShow) {
                setTimeout(() => {
                    setPendingUserModalShow(true);
                }, 300)
            }
        } else if (res.data.message.MSG_CODE === 100500) {
            Swal.fire({
                icon: 'error',
                text: 'Something went, please try again later',
                timer: 3000
            })
        }
    }
    const clsePendingUserLisModel = () => {
        setPendingUserModalShow(false);
        setPendingUserPageData({});
        setChannelsPedingUser([]);
        setCurrentChannelId('');
        // empty();
    }
    // accept and reject pending request of user for private channel
    const acceptReuqest = async (userId, channelId) => {
        var data = {
            user_id: userId,
            approve_users: true,
            channel_id: channelId,
            approve: true,
            reject: false
        };
        let res = await dispatch(apiCall(data, '/user/channel-admin'));
        if (res.data.message.MSG_CODE === 100371) {
            openPendingUserLisModel(channelData.channel_id );
            Swal.fire({
                icon: 'success',
                text: 'User accept successfully',
                timer: 3000
            })
        } else if (res.data.message.MSG_CODE === 100410) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    const rejectReuqest = async (userId, channelId) => {
        var data = {
            user_id: userId,
            approve_users: true,
            channel_id: channelId,
            approve: false,
            reject: true
        };
        let res = await dispatch(apiCall(data, '/user/channel-admin'));
        if (res.data.message.MSG_CODE === 100371) {
            openPendingUserLisModel(channelData.channel_id);
            Swal.fire({
                icon: 'success',
                text: 'User has been rejected',
                timer: 3000
            })
        } else if (res.data.message.MSG_CODE === 100410) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    // pendding user model 
    const openPendingUserLisModel = async (id , page = 1) => {
        console.log(page)
        setCurrentChannelId(id)
        var data = {
            get_pending_users_of_private_channel: true,
            per_page: 10,
            channel_id: id,
            page_number: page
        }
        let res = await dispatch(apiCall(data, '/user/channel-admin'));
        if (res.data.message.MSG_CODE === 100441) {
            setPendingUserPageData(res.data.pagination);
            setChannelsPedingUser(res.data.data);
            setTimeout(() => {
                setPendingUserModalShow(true);
            }, 500)
        } else if (res.data.message.MSG_CODE === 100500) {
            Swal.fire({
                icon: 'error',
                text: 'Something went, please try again later',
                timer: 3000
            })
        }
    }
    // get category
    const getAttributesData = async (pg) => {
        var data = {
            is_get: true,
            content_language: 'EN',
            per_page: 10,
            page_number: pg
        };
        let res = await dispatch(apiCall(data, '/user/admin-category'));
        if (res.data.message.MSG_CODE === 100661) {
            let newUniArray = [];
            let newPostAttributs = [];
            for (let i = 0; i < res.data.data.length; i++) {
                if(res.data.data[i].type == "CATEGORY"){
                newUniArray.push({ 'label': res.data.data[i].category_title, 'value': res.data.data[i].category_id })
                newPostAttributs.push(res.data.data[i])
                }
            }
            setPostAttribute(newPostAttributs);
            setFilterAttribute(newUniArray);
        }
        else {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    // channel initials
    const channelInitials = (item) => {
        let initials = item.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
        setChannelInitial(initials)
    }
    // attribute 
    const attributeSelected = (item) => {
        if (selectedAttribute === item) {
            setSelectedAttribute({})
        } else {
            setSelectedAttribute(item)
        }
    }
    // get user data
    const openModel = async (user) => {
        var data = {
          email: user,
          get_user_data: true,
        };
        let res = await dispatch(apiCall(data,'/user/approve'));
        if (res.data.message.MSG_CODE === 100441) {
          setPorfileDetails(res.data.data);
          setTimeout(() => {
            setModalShow(true);
          }, 500)
        } else if (res.data.message.MSG_CODE === 100020) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            text: "User does not exist",
            timer: 3000,
            showConfirmButton: false,
          })
        }
      }
      // model operation
    const closeModel = () => {
        setPorfileDetails({})
        setModalShow(false);
    }

    const postValueSelected = (item) => {
        setIsPostValue(item)
    }
    return (
        <>
            <section>
                <Row style={{ height: '100vh' }}>
                    <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
                        <LeftSideBar />
                    </Col>
                    <Col className='bg-prime p-0 channelScreen' xxl={10} xl={10} lg={9} md={9} style={{ height: '100%' }}>
                        <Container className='log-wrap p-0' fluid style={{ height: '100vh' }}>
                            <>
                                <section className='px-3 py-3' style={{ height: '100%' }}>
                                    <Row className='w-100'>
                                        <h5 className='w-25'>Channel</h5>
                                        <hr></hr>
                                    </Row>
                                    <div className='pt-2 indivisualChannelDiv' id="scrollableDiv">
                                        <Row>
                                            <Col xxl={9} xl={9} lg={11} md={11} className='p-0' style={{ height: '100%', margin: '0 auto' }}>
                                                <div className='channelDetails'>
                                                    <div className='channelBannerImage'>
                                                        {
                                                            channelData.banner_image && channelData.banner_image !== null ?
                                                                <img src={channelData.banner_image} ref={topRef} alt='banner_image' /> :
                                                                <div className='bannerImageNull'>
                                                                    <p className='bannerImagNullName'>{channelInitial}</p>
                                                                </div>
                                                        }
                                                        <div className='channelName d-flex justify-content-between align-items-center'>
                                                            <div className=''>
                                                                <h5 className='mt-2'>{channelData.channel_name}</h5>
                                                                <p className='m-0'>{channelData.channel_type} Channel</p>
                                                            </div>
                                                            {
                                                                
                                                                logedinRole == 'ADMIN' ? (  
                                                                    channelData.channel_type === 'Private' ? 
                                                                
                                                                    <div>
                                                                        <Button onClick={() => { openPendingUserLisModel(channelData.channel_id) }}>Pending user <img style={{ height: '1.2em', width: '1.2em' }} alt='donwload' src={image.downloadImage} /></Button>
                                                                    </div> :
                                                                    <div>
                                                                        <Button onClick={() => { downloadCsvList(channelData.channel_id) }}>User list<img style={{ height: '1.2em', width: '1.2em', marginLeft: '0.3em' }} alt='donwload' src={image.downloadImage} /></Button>
                                                                    </div>
                                                                ) : null
                                                            

                                                        }
                                                        </div>
                                                    </div>
                                                    <span className='channelThumbImage'>
                                                        {
                                                            channelData.thumbnail_image !== null ?
                                                                <img src={channelData.thumbnail_image} alt='thumbnail_image' /> :
                                                                <div className='thumbNailImageNull'>
                                                                    <p className='thumbNailImageNullImage m-0'>{channelInitial}</p>
                                                                </div>
                                                        }
                                                    </span>
                                                </div>
                                                <div className='postDiv'>
                                                    <div className='uploadPostDiv mt-1 bg-white pt-3'>
                                                        <div className='profileImagePicDiv d-flex'>
                                                            <span className='w-25'>
                                                                {
                                                                    channelData.thumbnail_image !== null ?
                                                                        <img className='profileImagePicChannel' src={channelData.thumbnail_image} alt='profileImagePicChannel' /> :
                                                                        <img className='profileImagePicChannel' src={image.porfilePicture} alt='thumbnail_image' />
                                                                }
                                                            </span>
                                                            <textarea
                                                                name="searchValue" className='postInputBox'
                                                                id='postTextInputBox'
                                                                rows="2" cols="200"
                                                                placeholder="What's happening"
                                                                value={isUploadText}
                                                                onChange={(e) => setIsUploadText(e.target.value)}
                                                                onKeyDown={changeHeightTextArea('#postTextInputBox')}
                                                            >
                                                            </textarea>
                                                        </div>
                                                        <div className='uploadPostInputDiv'>
                                                            <div >
                                                                <div className='d-flex py-2 uploadPostInput' >
                                                                    <div className='d-flex justify-content-center uploadOptionDiv' style={{ background: isImageUpload === true ? '#389ffe5c' : '' }}>
                                                                        <img className='mx-2' src={image.galleryImage} style={{ width: '1.4em' }} alt='galleryImage' />
                                                                        <input type="file" id="uploadImageInputDiv" name="avatar" accept=" image/png, image/jpeg" multiple onChange={(event) => uploadImage(event.target.files, 'image')} />
                                                                        <span className='uploadOptionDivName'>Photo</span>
                                                                    </div>

                                                                    <div className='d-flex justify-content-center uploadOptionDiv' style={{ background: isAudioUpload === true ? '#389ffe5c' : '' }}>
                                                                        <img className='mx-2' src={image.audioImage} alt='audioImage' style={{ width: '1.4em' }} />
                                                                        <input type="file" id="uploadAudioInputDiv" name="avatar" accept="audio/mp3, audio/mpeg,audio/mpg" onChange={(event) => uploadFile(event.target.files, 'audio')} />
                                                                        <span className='uploadOptionDivName'>Audio</span>
                                                                    </div>
                                                                    <div className='d-flex justify-content-center uploadOptionDiv' style={{ background: isFileUpload === true ? '#389ffe5c' : '' }}>
                                                                        <img className='mx-2' src={image.attachmentIconImage} alt='audioImage' style={{ width: '1.4em' }} />
                                                                        <input type="file" id="uploadFileInputDiv" name="avatar" accept="application/pdf, application/vnd.ms-excel" onChange={(event) => uploadFile(event.target.files, 'file')} />
                                                                        <span className='uploadOptionDivName'>File</span>
                                                                    </div>
                                                                    <Form className='uploadOptionDiv'>
                                                                        <Form.Group style={{ display: showProgress ? "none" : "flex" }} controlId="formFileSm" className="text-center w-100 adminUploadVideoForm">
                                                                            <Form.Control type="file" size="sm" accept="video/mp4" onChange={(e) => { getFileContext(e) }} />
                                                                            <img className='mx-2' src={image.videoImage} alt='videoImage' />
                                                                            <Form.Label className='uploadOptionDivName mb-0'>Video</Form.Label>
                                                                        </Form.Group>
                                                                        <Form.Group style={{ display: showProgress ? "block" : "none", height: '100%', borderRadius: '1rem' }}>
                                                                            {progressInstance}
                                                                        </Form.Group>
                                                                    </Form>
                                                                </div>
                                                                <div className='d-flex pb-2 uploadBtnDiv' >
                                                                <div>
                                                                <DropdownButton id="dropdown-basic-button px-1 " title={isPostValue.length > 10 ? isPostValue.slice(0, 10) + '...' : isPostValue}>
                                                                        {
                                                                            postValues && postValues.length > 0 && postValues.map((item, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <Dropdown.Item className={isPostValue === item ? 'active' : ''} onClick={() => postValueSelected(item)} >{item}</Dropdown.Item>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </DropdownButton>
                                                                    </div>
                                                                    <div className=' d-flex px-1'>
                                                                        <DropdownButton id="dropdown-basic-button px-1" title={JSON.stringify(selectedAttribute) === '{}' ? "Attributes" : selectedAttribute.category_title}>
                                                                            {postAttribute && postAttribute.length > 0 && postAttribute.map((item, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <Dropdown.Item className={selectedAttribute === item ? 'active' : ''} onClick={() => attributeSelected(item)} >{item.category_title}</Dropdown.Item>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </DropdownButton>
                                                                    </div>
                                                                    <div className='uploadButton'>
                                                                        <Button className='uploadButton w-100' onClick={() => uploadPostChannel()}>Post</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='my-2 filter-div-channel' style={{}} >
                                                            <h6 style={{ 'marginRight': '1rem' }}>Filter posts  </h6>
                                                            {
                                                                filterAttribute !== undefined ?
                                                                    <>
                                                                        <MultiSelect
                                                                            options={filterAttribute}
                                                                            value={selectedFilterAttribute}
                                                                            onChange={setSelectedFilterAttribute}
                                                                            labelledBy="Select"
                                                                            className='custom-multiselect-class-channel'
                                                                        />
                                                                    </> : null

                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        feedDataValue.length > 0 ?
                                                            <InfiniteScroll
                                                                dataLength={feedDataValue.length}
                                                                next={getChannelNewData}
                                                                style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                                                                hasMore={isMoreData}
                                                                scrollableTarget="scrollableDiv"
                                                            >
                                                                <div className=''>
                                                                    {
                                                                        feedDataValue.map((feed, index) => {
                                                                            return (
                                                                                <div key={index}>
                                                                                    <MediaGrid feedData={feed} deletePost={setIsDeletePost} isEditPost={setIsEditPost} indexOfFeed={index} sharePost={setIsSharePost} permissions={permissions} isAttributeSelected={selectedFilterAttribute.length > 0 ? true : false} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </InfiniteScroll> :
                                                            <>
                                                                <h5 style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '2rem', color: 'steelblue' }}>
                                                                    No Data available
                                                                </h5>
                                                            </>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </section>
                            </>
                        </Container>
                    </Col>
                </Row>
            </section>
            <Modal
                show={pendingUserModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Pending User List
                    </Modal.Title>
                    <InputGroup className="mb-2 mx-3 my-2 w-50">
                        <Form.Control
                            placeholder="User"
                            aria-label="User"
                            aria-describedby="basic-addon1"
                            value={searchUser}
                            onChange={(e) => setSearchUser(e.target.value)}
                        />
                        {/* <Button variant="primary" onClick={() => { searchUserChannel() }}><AiIcons.AiOutlineSearch /></Button> */}
                    </InputGroup>
                    <CloseButton onClick={clsePendingUserLisModel} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div className='tableClassChannel'>
                                    {
                                        channelsPedingUser.length > 0 ?
                                            <Table hover striped size="sm" className='table-responsive'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        channelsPedingUser.map((user, index) => {
                                                            return (
                                                                <tr key={index} className='' role='button' onClick={() => openModel(user.email)}>
                                                                    <td>{user.full_name}</td>
                                                                    <td role='button'>
                                                                        <span style={{ color: 'green' }} onClick={() => { acceptReuqest(user.user_id, channelData.channel_id) }}><BsIcons.BsCheckCircleFill /></span>
                                                                        <span className='requestAction' style={{ color: 'red' }} onClick={() => { rejectReuqest(user.user_id, channelData.channel_id) }}><BsIcons.BsXCircleFill /></span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table> :
                                            <h6 className='text-center'>No pending user</h6>

                                    }
                                    {
                                        pendingUserPageData.total_page > 1 && (
                                            <ReactPaginate
                                                className='pagination'
                                                breakLabel={'...'}
                                                nextLabel="next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={2}
                                                pageCount={pendingUserPageData.total_page}
                                                previousLabel="< prev"
                                                renderOnZeroPageCount={null}
                                                containerClassName="pagination"
                                                activeClassName={'active'}
                                                pageClassName={'page-item'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link'}
                                                pageLinkClassName={'page-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link'}
                                            />
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => { downloadCsvList(channelData.channel_id) }} >Download approved users list</Button>
                    <Button onClick={clsePendingUserLisModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                {...profileDetails}
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Account Summary
                    </Modal.Title>
                    <CloseButton onClick={closeModel} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={4} xl={4} lg={5} md={5} style={{ borderRight: '1px solid #b2cedb' }}>
                                <div style={{ textAlign: 'center' }}>
                                    {
                                        profileDetails?.profile_picture === null ?
                                            <img src={image.porfilePicture} alt='logo' style={{ height: '8rem', width: '8rem', borderRadius: '50%', marginRight: '1em' }} />
                                            : <img src={profileDetails.profile_picture} alt='logo' style={{ height: '8rem', width: '8rem', borderRadius: '50%', marginRight: '1em' }} />
                                    }
                                </div>
                                <div className='profileDetails' style={{ marginTop: '1.5em', paddingLeft: '2em' }}>
                                    <span>Full Name</span>
                                    <h6 className='m-0'>{profileDetails.full_name}</h6>
                                </div>
                                <div className='profileDetails' style={{ marginTop: '1em', paddingLeft: '2em' }}>
                                    <span>Email Address</span>
                                    <h6 className='m-0'>{profileDetails.email}</h6>
                                </div>
                            </Col>
                            <Col xxl={7} xl={7} lg={7} md={7}>
                                <div className='d-flex my-2'>
                                    <Col className='profileDetails'>
                                        <span>Phone number</span>
                                        {

                                            profileDetails.phone !== null && profileDetails.phone !== '' ?
                                                <h6 className='m-0'>{profileDetails.phone}</h6>
                                                : <h6 className='m-0'>Not available</h6>
                                        }
                                    </Col>
                                    <Col className='profileDetails'>
                                        <span>Role</span>
                                        {
                                            profileDetails.role !== null && profileDetails.role !== '' ?
                                                <h6 className='m-0'>{profileDetails.role}</h6>
                                                : <h6 className='m-0'>Not available</h6>
                                        }
                                    </Col>
                                </div>
                                <div className='d-flex my-2'>
                                    <Col className='profileDetails'>
                                        <span>Primary speciality</span>
                                        {
                                            profileDetails.primary_speciality !== null && profileDetails.primary_speciality !== '' ?
                                                <h6 className='m-0'>{profileDetails.primary_speciality}</h6>
                                                : <h6 className='m-0'>Not available</h6>
                                        }
                                    </Col>
                                    <Col className='profileDetails'>
                                        <span>Secondary speciality</span>
                                        {
                                            profileDetails.secondary_speciality !== null && profileDetails.secondary_speciality !== '' ?
                                                <h6 className='m-0'>{profileDetails.secondary_speciality}</h6>
                                                : <h6 className='m-0'>Not available</h6>
                                        }
                                    </Col>
                                </div>
                                <div className='d-flex my-2'>
                                    <Col className='profileDetails'>
                                        {
                                            profileDetails.role === "STUDENT" && internalPermissions.includes("student_id") &&
                                            <div>
                                                <span>Student Id</span>
                                                {
                                                    profileDetails.student_id_number !== null && profileDetails.student_id_number !== '' ?
                                                        <h6 className='m-0'>{profileDetails.student_id_number}</h6> : <h6 className='m-0'>Not available</h6>
                                                }
                                            </div>

                                        }
                                        {
                                            profileDetails.role === "DOCTOR" && internalPermissions.includes("professional_license") &&
                                            <div>
                                                <span>Professional license</span>
                                                {
                                                    profileDetails.professional_license_number !== null && profileDetails.professional_license_number !== '' ?
                                                        <h6 className='m-0'>{profileDetails.professional_license_number}</h6> : <h6 className='m-0'>Not available</h6>
                                                }
                                            </div>
                                        }
                                    </Col>
                                </div>
                                {
                                    profileDetails.educational_information &&
                                    <div className='d-flex my-2'>
                                        <Col className='profileDetails'>
                                            {
                                                profileDetails.educational_information.map((educ, index) => {
                                                    return (
                                                        <div className='d-flex'>
                                                            <Col className='profileDetails'>
                                                                <div>
                                                                    <span>Education Degree</span>
                                                                    {
                                                                        educ.educational_degree !== null && educ.educational_degree !== '' && educ.educational_degree !== undefined ?
                                                                            <h6 className='m-0'>{educ.educational_degree}</h6>
                                                                            : <h6 className='m-0'>Not available</h6>
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col className='profileDetails'>
                                                                <span>Education Institute</span>
                                                                {
                                                                    educ.educational_institute !== null && educ.educational_institute !== '' ?
                                                                        <h6 className='m-0'>{educ.educational_institute}</h6>
                                                                        : <h6 className='m-0'>Not available</h6>
                                                                }
                                                            </Col>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </div>
                                }
                                <div className='d-flex my-2'>
                                    <Col className='profileDetails'>
                                        <span>District</span>
                                        {
                                            profileDetails.district !== null && profileDetails.district !== '' ?
                                                <h6 className='m-0'>{profileDetails.district}</h6>
                                                : <h6 className='m-0'>Not available</h6>
                                        }
                                    </Col>
                                    <Col className='profileDetails'>
                                        <span>City</span>
                                        {
                                            profileDetails.province_city !== null && profileDetails.province_city !== '' ?
                                                <h6 className='m-0'>{profileDetails.province_city}</h6>
                                                : <h6 className='m-0'>Not available</h6>
                                        }
                                    </Col>
                                </div>
                                {
                                    profileDetails.other_information &&
                                    <div className='d-flex my-2'>
                                        <Col className='profileDetails'>
                                            <span>Other information</span>
                                            {
                                                profileDetails.other_information !== null && profileDetails.other_information !== '' ?
                                                    <h6 className='m-0'>{profileDetails.other_information}</h6>
                                                    : <h6 className='m-0'>Not available</h6>
                                            }
                                        </Col>
                                    </div>
                                }
                                <div className='d-flex my-2'>
                                    {
                                        Object.keys(profileDetails).length !== 0 ?
                                            <Col className='profileDetails'>
                                                {
                                                    profileDetails.role === "STUDENT" && internalPermissions.includes("student_id") &&
                                                    <div>
                                                        <span>Student Id</span>
                                                        {
                                                            profileDetails.student_id_number !== null || profileDetails.student_id.length > 0 ?
                                                                <a href={profileDetails.student_id[profileDetails.student_id.length - 1]} target="_blank" rel="noreferrer" className='mx-2'>Preview</a> : <p>no data</p>
                                                        }
                                                    </div>

                                                }
                                                {
                                                    profileDetails.role === 'DOCTOR' && internalPermissions.includes("professional_license") &&
                                                    <div>
                                                        <span>Professional license</span>
                                                        {profileDetails.professional_license_number !== null || profileDetails.professional_license.length > 0 ?
                                                            <a href={profileDetails.professional_license[profileDetails.professional_license.length - 1]} target="_blank" rel="noreferrer" className='mx-2'>Preview</a> : <p>no data</p>}
                                                    </div>
                                                }

                                            </Col> : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default IndivisualChannel