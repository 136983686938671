import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import LeftSideBar from '../../SideBar/LeftSideBar';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import * as AiIcons from 'react-icons/ai';
import * as GoIcons from 'react-icons/go';
import * as BsIcons from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiCall } from '../../../../action/loginAction';
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import image from '../../../../constant/image';
import './Events.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { actionpermission } from '../../../../constant/commonFunctions'
import Badge from 'react-bootstrap/Badge';
import useDebounce from '../../../../constant/useDebouncing';
import moment from 'moment';
import { getShareLink } from '../../../../constant/getShareLink';
import Unauthorized from './Unauthorized';
const Events = () => {
    var navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDeleteEvent, setIsDeleteEvent] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [modelData, setModelData] = useState('');
    const [eventData, setEventData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setendDate] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [permissions, setPermissions] = useState([])
    const [showMoreTopicTag, setShowMoreTopicTag] = useState(false);
    const [eventValue, setEventValue] = useState('');
    const debouceSearchTerm = useDebounce(eventValue, 600);
    const initialRender = useRef(true);
    const [userType,setUserType] = useState("Admin");
    const [logedinRole, setLogedinRole] = useState('')
    //get today event 
    useEffect(() => {
        let permissionsArray = actionpermission("Events")
        setPermissions(permissionsArray)
        let logedinrole = localStorage.getItem("role")
        setLogedinRole(logedinrole)
    }, []);
    useEffect(() => {
        if (startDate === null && endDate === null) {
            getListEvent("", "", 1);
        }
    }, [startDate, endDate]);
    //debounce
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        }else{
            if (debouceSearchTerm) {
                searchEvent(debouceSearchTerm)
            }else{
                getListEvent("", "", 1);
            }
        }
    }, [debouceSearchTerm])
    // model operation
    const showEventModel = (data) => {
        setModelData(data)
        setModalShow(true)
    }
    const closeModel = () => {
        setModalShow(false);
        setShowMoreTopicTag(false)
    }
    //Event Related function edit delete
    const editEvent = (modaldata) => {
        navigate("/admin/events/editevent ", { state: { data: modaldata } });
    }
    const searchButtonClick = () => {
        let startDat = new Date(startDate);
        startDat = formatDate(startDate);
        let endDat = new Date(endDate);
        endDat = formatDate(endDate);
        getListEvent(startDat, endDat, 1);

    }
    const deleteEventSwal = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this Event",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed === true) {
                deleteEvent(id);
                setIsDeleteEvent(false);
            }
        })
    }
    const deleteEvent = async (id) => {
        var data = {
            delete_event: true,
            event_id: id
        };
        let res = await dispatch(apiCall(data, '/user/organize-event'));
        if (res.data.message.MSG_CODE === 100411) {
            Swal.fire({
                icon: 'success',
                text: 'Event has been delete successfully',
                timer: 4000
            })
        }
        else if (res.data.message.MSG_CODE === 100440) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again!',
                timer: 4000
            })
        }
        setIsDeleteEvent(false);
        handlePageClick(page)
    }

    //get event list 
    const getListEvent = async (startDate, endDate, pgNo) => {
        var data = {
            get_events_list_by_date: true,
            start_event_date: startDate === "" ? null : startDate,
            end_event_date: endDate === "" ? null : endDate,
            per_page: 8,
            page_number: pgNo
        };
        let res = await dispatch(apiCall(data, '/user/organize-event'));
        if (res.data.message.MSG_CODE === 100481) {
            setEventData(res.data.data);
            setPageData(res.data.pagination);
        }
        else if (res.data.message.MSG_CODE === 100130){
            setUserType("Enterprise")
        }
        else if (res.data.message.MSG_CODE === 100500) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again!',
                timer: 4000
            })
        }
    }
    // search event list
    const searchEvent = async (eventData) => {
        var data = {
            search_event_by_keyword: true,
            keyword: eventData
        };
        let res = await dispatch(apiCall(data, '/user/organize-event'));
        if (res.data.message.MSG_CODE === 100481) {
            setEventData(res.data.data);
            setPageData(res.data.pagination);
        }
        else if (res.data.message.MSG_CODE === 100500) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again!',
                timer: 4000
            })
        }
    }
    //get data dd/mm/yyyy
    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }
    const formatDate = (date) => {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }
    // download csv file 
    const downloadCsvList = async (id) => {
        var data = {
            download_attendees: true,
            event_id: id
        };
        let res = await dispatch(apiCall(data, '/user/organize-event'));
        if (res.data.message.MSG_CODE === 100231) {
            downloadFile(res.data.data)
        } else if (res.data.message.MSG_CODE === 100721) {
            Swal.fire({
                icon: 'warning',
                text: 'No user registered',
                timer: 4000
            })
        } else if (res.data.message.MSG_CODE === 100670 || res.data.message.MSG_CODE === 100510) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again!',
                timer: 4000
            })
        }
    }

    const downloadFile = (urlData) => {
        var blob = new Blob([urlData], { type: 'text/csv;charset=utf-8;' })
        var link = document.createElement("a");
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", urlData);
        link.setAttribute("download", "exportedFilenmae");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    //pagination
    const handlePageClick = (data) => {
        let currentPage = ''
        if (data.selected !== undefined) {
            currentPage = data.selected + 1;
        }else{
            currentPage = data;
        }
        setPage(currentPage)
        getListEvent(startDate, endDate, currentPage);
    }
    // add line brk 
    const newlinesTexts = (text, id) => {
        text = text.replace(/\n/g, "<br />")
        return text;
    }
    // get share link 
    const generatePostLink = async(event) =>{
        let resultLink = await getShareLink('event',event.event_id, event.title)
        navigator.clipboard
        .writeText(resultLink)
        .then(() => {
            alert("successfully copied");
        })
        .catch(() => {
            alert("something went wrong");
        });
    }
    return (
        <>
            <section>
                <Row style={{ height: '100vh' }}>
                    <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
                        <LeftSideBar />
                    </Col>
                    {
                        userType === "Admin" ? ( <Col className='bg-prime p-0 eventScreen' xxl={10} xl={10} lg={9} md={9}>
                        <Container className='log-wrap p-0' fluid style={{ height: '100%' }}>
                            <React.Fragment>
                                <section className='px-3 pt-3' style={{ height: '100%' }} >
                                    <div>
                                        <h5>Events</h5>
                                        <hr></hr>
                                    </div>
                                    <Row className='d-flex'>
                                        <Col className="input-group mb-1 d-flex justify-content-between px-2">
                                            <Col xxl={5} xl={8} lg={8} md={12} className='px-2' >
                                                <InputGroup className="mb-2 w-50">
                                                    <Form.Control
                                                        placeholder="Events"
                                                        aria-label="Events"
                                                        value={eventValue}
                                                        aria-describedby="basic-addon1"
                                                        onChange={(e) => setEventValue(e.target.value)}
                                                    />
                                                    {
                                                        eventValue.trim().length === 0 ?
                                                        <span role='button' className='searchIcon' onClick={() => { }}><BsIcons.BsSearch /></span> :
                                                        <span role='button' className='searchIcon' onClick={() => {setEventValue('')}}><AiIcons.AiOutlineClose /></span>
                                                    }
                                                </InputGroup>
                                            </Col>
                                            <Col xxl={7} xl={4} lg={4} md={12} className='justify-content-between d-flex px-3'>
                                                <div style={{ height: '2em' }} className='py-2'>
                                                    {/* <DateRangePicker onChange={(value) => onChangeDate(value)} value={value} format='dd/MM/y' /> */}
                                                </div>
                                                {
                                                    permissions.includes("Add event") &&
                                                    <div>
                                                        <NavLink to='addevent'><Button variant="primary" className='addEventBtn' style={{ height: '2.3rem' }}>Add event</Button></NavLink>
                                                    </div>
                                                }
                                            </Col>
                                        </Col>
                                    </Row>
                                    <div className='eventDataDiv'>
                                        <Row className='align-items-center'>
                                            <Col xxl={5} xl={4} lg={5} md={5} className='px-2 startEndDate' >
                                                <InputGroup className="mb-2 mx-2">
                                                    <h5>From date </h5>
                                                    <div className='px-2'>
                                                        <Col className='justify-content-between datePickerSelector' xxl={4} xl={4} lg={6} md={4}>
                                                            <DatePicker
                                                                placeholderText='DD-MM-YYYY'
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                                dateFormat='dd/MM/yyyy'
                                                            />
                                                        </Col>
                                                    </div>
                                                </InputGroup>
                                            </Col>
                                            <Col xxl={5} xl={4} lg={5} md={5} className='px-2 startEndDate' >
                                                <InputGroup className="mb-2">
                                                    <h5>To date </h5>

                                                    <div className='px-2'>
                                                        <Col className='justify-content-between datePickerSelector' xxl={4} xl={4} lg={6} md={4}>
                                                            <DatePicker
                                                                placeholderText='DD-MM-YYYY'
                                                                selected={endDate}
                                                                onChange={(date) => setendDate(date)}
                                                                dateFormat='dd/MM/yyyy'
                                                            />
                                                        </Col>
                                                    </div>
                                                </InputGroup>
                                            </Col>
                                            <Col xxl={2} xl={4} lg={2} md={2} className='searchBttn' >
                                                <Button variant="primary" style={{ height: '2.2rem' }} onClick={() => { searchButtonClick() }}>Search</Button>
                                            </Col>
                                        </Row>
                                        <Row >
                                            {
                                                eventData.length > 0 ?
                                                <>

                                                    {
                                                        eventData.map((event, index) => {
                                                            return (
                                                                <Col xxl={3} xl={3} lg={4} md={4} className='my-2' key={index}>
                                                                    <div className='indivisualEvent'>
                                                                        <span className='mx-2' style={{ color: '#b8b7b7', float: 'right' }} onClick={() => { deleteEventSwal(event.event_id) }}><BsIcons.BsXCircleFill /></span>
                                                                        <div onClick={() => showEventModel(event)}>
                                                                            <div className='indivisualEventImage'>
                                                                                <img src={event.banner_image} alt='banner image'
                                                                                />
                                                                            </div>
                                                                            <div className='indivisualEventPageinfo'>
                                                                                <div>
                                                                                    <strong className='m-0'>{event.title}</strong>
                                                                                    <div className='indivisualEventDate'>
                                                                                        <AiIcons.AiTwotoneCalendar />
                                                                                        <span>{moment(event.start_event_datetime).utcOffset(7).format('MMMM Do YYYY, h:mm:ss a')} (VN)</span>

                                                                                    </div>
                                                                                    <div className='indivisualEventLocation'>
                                                                                        <GoIcons.GoLocation />
                                                                                        <span className='mx-1'> {event.location}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        pageData.total_page > 1 && (
                                                            <div>
                                                                <ReactPaginate
                                                                    className='pagination'
                                                                    breakLabel={'...'}
                                                                    nextLabel="next >"
                                                                    onPageActive={page}
                                                                    onPageChange={handlePageClick}
                                                                    pageRangeDisplayed={2}
                                                                    marginPagesDisplayed={2}
                                                                    pageCount={pageData.total_page}
                                                                    previousLabel="< prev"
                                                                    renderOnZeroPageCount={null}
                                                                    containerClassName="pagination"
                                                                    activeClassName={'active'}
                                                                    pageClassName={'page-item'}
                                                                    previousClassName={'page-item'}
                                                                    previousLinkClassName={'page-link'}
                                                                    pageLinkClassName={'page-link'}
                                                                    nextClassName={'page-item'}
                                                                    nextLinkClassName={'page-link'}
                                                                    breakClassName={'page-item'}
                                                                    breakLinkClassName={'page-link'}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </>:
                                                <h5 style={{ textAlign: 'center', marginTop: '2rem', color: 'steelblue' }}>
                                                    No Data available
                                                </h5>
                                            }
                                        </Row>
                                    </div>
                                </section>
                            </React.Fragment>
                        </Container>
                    </Col>) : (
                        <Col className='bg-prime p-0 channelScreen' xxl={10} xl={10} lg={9} md={9} style={{ height: '100%' }}>
                            <Unauthorized/>
                        </Col>
                    )
                    }
                   
                </Row>
            </section>
            <Modal
                {...modelData}
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Event Detail
                    </Modal.Title>
                    <CloseButton onClick={closeModel} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            {
                                modelData !== '' ?
                                    <Col xxl={12} xl={12} lg={12} md={12}>
                                        <div className='modeEventImage'>
                                            <img src={modelData.banner_image} alt='bannerImage'
                                            />
                                        </div>
                                        <div className='modelEventPageinfo'>
                                        {
                                                                
                                                                logedinRole == 'ADMIN' ? 
                                            <div className='d-flex justify-content-between align-item-center'>
                                                <h6 className='m-0'>{modelData.title}</h6>
                                                <Button onClick={() => { downloadCsvList(modelData.event_id) }}>Download user list</Button>
                                            </div> : null
}
                                            <div className='modelEventDate my-2 d-flex'>
                                                <div>
                                                    <img src={image.calenderImage} alt='logo' style={{ height: '1.5rem', width: '1.5rem' }} />
                                                </div>
                                                <div className='mx-2'>
                                                    <div className='d-flex'>
                                                        <strong className='mx-1'>Start date :-</strong>
                                                            <div>
                                                                <p>{moment(modelData.start_event_datetime).utc().format('MMMM Do YYYY, h:mm:ss a')} (UTC)</p>
                                                                <p>{moment(modelData.start_event_datetime).utcOffset(7).format('MMMM Do YYYY, h:mm:ss a')} (VN)</p>
                                                            </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <strong className='mx-1'>End date :-</strong>
                                                            <div>
                                                                <p>{moment(modelData.end_event_datetime).utc().format('MMMM Do YYYY, h:mm:ss a')} (UTC)</p>
                                                                <p>{moment(modelData.end_event_datetime).utcOffset(7).format('MMMM Do YYYY, h:mm:ss a')} (VN)</p>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='modelEventLocation d-flex align-items-center my-2'>
                                                <img src={image.locationImage} alt='logo' style={{ height: '1.5rem', width: '1.5rem' }} />
                                                <strong className='mx-2'>Location :-</strong>
                                                <p className='m-0'>{modelData.location}</p>
                                            </div>
                                            <div className='modelEventModeCommu d-flex align-items-center my-2'>
                                                <img src={image.modeImage} alt='logo' style={{ height: '1.3rem', width: '1.5rem' }} />
                                                <strong className='mx-2'>Mode :-</strong>
                                                <p className='m-0'>{modelData.mode}</p>
                                            </div>
                                            <div className='modelEventModeCommu d-flex align-items-center my-2'>
                                                <img src={image.ticketImage} alt='logo' style={{ height: '1.3rem', width: '1.5rem' }} />
                                                <strong className='mx-1'>Paid / Free :-</strong>
                                                <p className='m-0'>({modelData.fees}₫ ){modelData.ticket_required === false ? ' Free' : ' Paid'}</p>
                                            </div>
                                            <div className='modelSpeakerDetail  my-2'>
                                                <img src={image.peopleImage} alt='logo' style={{ height: '1.5rem', width: '1.5rem' }} />
                                                <strong className='mx-1'>Speaker Name:-</strong>
                                                <ul className='modelSpeakerDetailSpeaker' style={{ listStyle: 'none' }}>
                                                    {
                                                        modelData.speaker_names.map((speaker, index) => {
                                                            return (
                                                                <div className='d-flex'>
                                                                 <span className='mx-1'>{index+1}{')'} </span><div key={index} dangerouslySetInnerHTML={{ __html: newlinesTexts(speaker) }} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                {
                                                    modelData.topic_tags && modelData.topic_tags.length>0 &&
                                                    <>
                                                        <img src={image.topicsImage} alt='logo' style={{ height: '1.5rem', width: '1.5rem' }} />
                                                        <strong className='mx-2'>Topic</strong>
                                                        <div className='modelSpeakerDetailTopic' style={{ marginRight: '1.5rem'}} >
                                                            {
                                                                modelData.topic_tags.map((topic, index) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                index < 22 &&
                                                                                <a><span key={index}>#{topic.name}</span></a>
                                                                            }
                                                                            {
                                                                                index === 22 && !showMoreTopicTag &&
                                                                                <Badge role="button" align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' onClick={() => setShowMoreTopicTag(!showMoreTopicTag)}>
                                                                                    Show More
                                                                                </Badge>
                                                                            }
                                                                            {
                                                                                showMoreTopicTag && index > 22 &&
                                                                                <a><span key={index}>#{topic.name}</span></a>
                                                                            }
                                                                            {
                                                                                index === modelData.topic_tags.length - 1 && showMoreTopicTag &&
                                                                                <Badge role="button" align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' onClick={() => setShowMoreTopicTag(!showMoreTopicTag)}>
                                                                                    Show less
                                                                                </Badge>
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {/* <ul className='d-flex modelSpeakerDetailTopic' style={{ listStyle: 'none' }} >
                                                    {
                                                        modelData.topic_tags.map((topic, index) => {
                                                            return (
                                                                <span key={index}>#{topic.name}</span>
                                                            )
                                                        })
                                                    }
                                                </ul> */}
                                            </div>
                                            <div className='modelEventdescription my-2'>
                                                <div><strong>Description:-</strong></div>
                                                <div
                                                    className='mx-2 modelEventdescriptionDiv '
                                                    dangerouslySetInnerHTML={{
                                                        __html: modelData.description
                                                    }}></div>
                                            </div>
                                        </div>
                                    </Col> : null
                            }
                        </Row>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        style={{marginRight:'auto'}}
                        onClick={()=>generatePostLink(modelData)}
                    >
                        Share Link
                    </Button>
                    <Button onClick={closeModel} style={{ background: 'white', color: 'blue' }}>Close</Button>
                    {
                        !modelData.is_start_date_expired &&
                        <Button onClick={() => { editEvent(modelData) }}>Edit</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Events