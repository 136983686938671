import React, { useEffect, useState, useRef } from 'react';
import LeftSideBar from '../../SideBar/LeftSideBar';
import { Row, Col, Container, ProgressBar, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { chunkUpload, apiCall } from '../../../../action/loginAction';
import image from '../../../../constant/image';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import InfiniteScroll from "react-infinite-scroll-component";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import CloseButton from 'react-bootstrap/CloseButton';
import MediaGrid from './MediaGrid';
import { MultiSelect } from "react-multi-select-component";
import Multiselect from 'multiselect-react-dropdown';
import './AdminPost.css'
import Badge from 'react-bootstrap/Badge';
import Switch from "react-switch";
import { actionpermission } from '../../../../constant/commonFunctions'
import * as BsIcons from 'react-icons/bs'
import * as AsIcons from 'react-icons/ai'
import InputGroup from 'react-bootstrap/InputGroup';
import SearchUserFeed from './SearchUser/SearchUserFeed';
import { useSelector } from 'react-redux';
import useDebounce from '../../../../constant/useDebouncing';
import { useLocation } from "react-router-dom";
import { compressionImage } from '../../../../constant/compressImage';
import * as AiIcons from 'react-icons/gi';
const chunkSize = 1048576 * 17;
const AdminPost = (props) => {
    const dispatch = useDispatch();
    const { isCallData } = useSelector(state => state.channelData);
    const { state } = useLocation();
    const [channelDataSearch, setChannelDataSearch] = useState(state)
    const [isPostValue, setIsPostValue] = useState('General');
    const [isUploadText, setIsUploadText] = useState('');
    const [isDeletePost, setIsDeletePost] = useState({ postID: '', indexOfPost: '' });
    const email = localStorage.getItem('email');
    const role = localStorage.getItem('role');
    const userId = localStorage.getItem('user_id');

    const [isImageUpload, setIsImageUpload] = useState(false);
    const [isAudioUpload, setIsAudioUpload] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [mideaList, setMideaList] = useState([]);
    const [feedData, setFeedData] = useState([]);
    const [isMoreData, setIsMoreData] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [registrationPageData, setRegistrationPageData] = useState([])
    const [provinceData, setProvinceData] = useState([]);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    // data for json
    const [primarySpeciality, setPrimarySpeciality] = useState([]);
    const [secondarySpeciality, setSecondarySpeciality] = useState([]);
    const [provinceCity, setProvinceCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [age, setAge] = useState([]);
    const [placeOfWork, setPlaceOfWork] = useState([]);
    const [isShowAdminPost, setIsShowAdminPost] = useState('');
    const [adminPostData, setAdminPostData] = useState([]);
    const [showAdminPostModal, setShowAdminPostModal] = useState(false)
    //model hide or show
    const [modalShow, setModalShow] = React.useState(false);
    //video uploading
    const [showProgress, setShowProgress] = useState(false)
    const [counter, setCounter] = useState(1)
    const [fileToBeUpload, setFileToBeUpload] = useState({})
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
    const [progress, setProgress] = useState(0)
    const [fileSize, setFileSize] = useState(0)
    const [chunkCount, setChunkCount] = useState(0)
    const [mediaId, setMediaId] = useState(null)
    const progressInstance = <ProgressBar style={{ height: '100%' }} animated now={progress} label={`${progress.toFixed(1)}%`} />;
    //pagination details
    const [page, setPage] = useState(1);
    // province city for speed change and update
    const [provinceCityData, setProvinceCityData] = useState([]);
    const [postAttribute, setPostAttribute] = useState([]);
    const [filterAttribute, setFilterAttribute] = useState([]);
    const [selectedFilterAttribute, setSelectedFilterAttribute] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState({});
    const [specialitiesList, setSpecialitiesList] = useState([]);
    const [univercityList, setUnivercityList] = useState([]);
    const [enterpriseUsersList, setEnterpriseUsersList] = useState([]);
    let [selectedEnterprise, setSelectedEnterprise] = useState([]);
    const [selectUnivercityList, setSelectUnivercityList] = useState([]);
    const [userType, setUserType] = useState('')
    const [isSharePost, setIsSharePost] = useState(false)
    const [practitionerType, setPractitionerType] = useState('surgeon')
    const postValues = ['General', 'Important','Pinned']
    const [showEnterpriseList, setShowEnterpriseList] = useState(false)
    const [permissions, setPermissions] = useState([])
    //
    const [searchPost, setSearchPost] = useState('');
    const [searchPostData, setSearchPostData] = useState([]);
    const debouceSearchTerm = useDebounce(searchPost, 600);
    const [isEditPost, setIsEditPost] = useState(false)
    const initialRender = useRef(true);
    const initialRender2 = useRef(true);
    const initialRender3 = useRef(true);
    const initialRender4 = useRef(true);
    const [paginatedCalled, setPaginatedCalled] = useState(false)

    const [workPlacesMaster, setWorkPlaceMaster] = useState([])

    //load profile pic
    const { isLoadImage } = useSelector(state => state.profileUrlData);
    const { profileUrl } = useSelector(state => state.profileUrlData);
    const { isSearchActive } = useSelector(state => state.feedData);
    const { isEditPostData } = useSelector(state => state.feedData);
    const { targetingPostId } = useSelector(state => state.feedData);

    // get data
    useEffect(() => {
        getFeedlData(1);
        getRegistrationPageData();
        getAttributesData(1);
        getEnterPriseUsers()
        dispatch({ type: "clearFeedData" });
        dispatch({ type: "clearCallChannelData" });
        dispatch({ type: "clearChannelFeedData" });
        let permissionsArray = actionpermission("Home")
        setPermissions(permissionsArray)
    }, [])

    //targetParameters
    useEffect(() => {
        if (targetingPostId.postId) {
            if (targetingPostId.postId.trim() !== '') {
                getAdminPostData(targetingPostId.postId)
            }
            dispatch({ type: "doneTargetingPara" });
        }

    }, [targetingPostId])
    useEffect(() => {
        if (!showEnterpriseList) {
            selectedEnterprise = []
        }
    }, [showEnterpriseList])
    //get after sharing
    useEffect(() => {
        if (initialRender2.current) {
            initialRender2.current = false;
        } else {
            if (isSharePost === true) {
                setFeedData([]);
                setIsSharePost(false)
                emptyFilterData();
                empty();
                setMideaList([])
                setIsDeletePost({ postID: '', indexOfPost: '' })
                setTimeout(() => {
                    getFeedlData(1);
                    getRegistrationPageData();
                    getAttributesData(1);
                }, 100);
            }
        }
    }, [isSharePost, searchPost])
    //delete post
    useEffect(() => {
        if (isDeletePost.postID !== '') {
            deletePostinAdminPost(isDeletePost)
        }
    }, [isDeletePost])
    useEffect(() => {
        if (fileSize > 0) {
            fileUpload(counter);
        }
    }, [fileToBeUpload, progress])
    useEffect(() => {
        if (isShowAdminPost !== '') {
            getAdminPostData(isShowAdminPost)
        }
    }, [isShowAdminPost])
    //
    useEffect(() => {
        if (initialRender3.current) {
            initialRender3.current = false;
        } else {
            getFeedlData(1)
            setFeedData([])
            setSearchPostData([])
        }
    }, [selectedFilterAttribute])
    // search term
    useEffect(() => {
        if (initialRender4.current) {
            initialRender4.current = false;
        } else {
            if (debouceSearchTerm) {
                setFeedData([])
                setSearchPostData([])
                setTotalPage(0);
                setPage(1)
                setIsMoreData(false)
                searchAdminData(1, debouceSearchTerm)
            } else {
                dispatch({ type: "clearFeedData" });
                setSearchPostData([])
                empty();
                getFeedlData(1)
                setTotalPage(0);
                setPage(1)
            }
        }
    }, [debouceSearchTerm, isCallData])
    //edit post
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            if (isEditPost === true) {
                getFeedlData(1);
                setIsEditPost(false)
            }
        }
    }, [isEditPost])
    // get profile pic
    useEffect(() => {
        if (isLoadImage) {
            getUserProfile()
        } else {
            dispatch({ type: "notGetImage" });
        }
    }, [isLoadImage])
    //get edit data
    useEffect(() => {
        if (isEditPostData) {
            searchAdminData(1, debouceSearchTerm)
            dispatch({ type: "doneFeedDataEdit" });
            setFeedData([])
            setSearchPostData([])
            setTotalPage(0);
            setPage(1)
            setIsMoreData(false)
        }
    }, [isEditPostData])
    //get userProfile
    const getUserProfile = async () => {
        var data = {
            get_profile: true,
            preferred_language: 'EN',
            user_id: userId
        };
        let res = await dispatch(apiCall(data, '/user/profile'));
        localStorage.setItem('profile_picture', res.data.data.profile_picture);
         dispatch({ type: "profileImage",...res.data.data});
    }

    // get feed data 
    const getFeedlData = async (pg) => {
        let filter_categories = selectedFilterAttribute.map(attr => {
            return attr.value
        })
        // setFeedData([])
        var data = {
            get_general_feed: true,
            per_page: 10,
            page_number: pg,
            preferred_language: 'EN',
            email,
            role,
            filter_categories
        };
        let res = await dispatch(apiCall(data, '/user/admin-feed'));
        if (searchPost.trim().length === 0) {
            dispatch({ type: "clearFeedData" });
        }
        if (res.data.message.MSG_CODE === 100161) {
            setFeedData(res.data.data);
            setTotalPage(res.data.pagination.total_page);
            if (res.data.pagination.total_page > 1) {
                setIsMoreData(true);
                setPage(pg + 1);
            }
        } else if (res.data.message.MSG_CODE === 100160) {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 10000
            })
        }
    }
    const toggleList = () => {
        setShowEnterpriseList(!showEnterpriseList)
    }
    const getNewFeedData = async () => {

        if (isEditPost || feedData.length === 0) {
            return
        }
        else {
            let filter_categories = selectedFilterAttribute.map(attr => {
                return attr.value
            })
            var data = {
                get_general_feed: true,
                per_page: 10,
                page_number: page,
                preferred_language: 'EN',
                email,
                role,
                filter_categories
            };
            let res = await dispatch(apiCall(data, '/user/admin-feed'));
            if (res.data.message.MSG_CODE === 100161) {
                setFeedData([...feedData, ...res.data.data]);
                if (page !== totalPage) {
                    setPage(page + 1);
                } else {
                    setIsMoreData(false);
                }
            } else if (res.data.message.MSG_CODE === 100160) {
                Swal.fire({
                    icon: 'error',
                    text: 'Post Update Failure',
                    timer: 10000
                })
            }
        }
    }

    const getEnterPriseUsers = async () => {
        var data = {
            tag_enterprise_users: true,
        };
        let res = await dispatch(apiCall(data, '/user/follow'));
        if (res.data.message.MSG_CODE === 100561) {
            let newUniArray = [];
            for (let i = 0; i < res.data.data.length; i++) {
                newUniArray.push({ 'label': res.data.data[i].company_name, 'value': res.data.data[i].company_name, 'user_name': res.data.data[i].company_name, 'user_id': res.data.data[i].user_id })
            }
            setEnterpriseUsersList(newUniArray);
        } else if (res.data.message.MSG_CODE === 100160) {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    if (selectedEnterprise.length > 1) {
        selectedEnterprise = selectedEnterprise.slice(1);
    }
    //empty all data
    const empty = () => {
        setFeedData([]);
        setIsUploadText('');
        setIsImageUpload(false);
        setIsAudioUpload(false);
        setIsFileUpload(false);
        setShowProgress(false);
        setIsSharePost(false);
        setTotalPage(0);
        setIsDeletePost({ postID: '', indexOfPost: '' })
        setSelectedEnterprise([]);
        setShowEnterpriseList(false)

    }
    // change height 
    const changeHeightTextArea = (id) => {
        setTimeout(() => {
            var textarea = document.querySelector(id);
            textarea?.addEventListener("keydown", e => {
                let scroll_height = e.target.scrollHeight;
                textarea.style.height = `${scroll_height}px`;
            });
        }, 10);
    }
    // specilaty
    const getSpecialityList = (list) => {
        let newSpecArray = [];
        for (let i = 0; i < list.length; i++) {
            newSpecArray.push({ 'label_name': list[i].label, 'identifier_id': list[i].value })
        }
        return newSpecArray
    }
    // userlist
    const getUserListtype = (type) => {
        let userListArray = [];
        if (type === 'student') {
            userListArray.push('STUDENT')
        } else if (type === 'practitioner') {
            userListArray.push('DOCTOR')
        } else   if (type === 'both') {
            userListArray.push('STUDENT')
            userListArray.push('DOCTOR')
        } else {
            userListArray = [];
        }
        return userListArray
    }
    const getPractionerType = (type , array) => {
        let userListArray = [...array];
        if (type === 'surgeon') {
            userListArray.push('SURGEON')
        } else if (type === 'physician') {
            userListArray.push('PHYISICIAN')
        } else   if (type === 'both') {
          
        }
        return userListArray
    }
    //upload text 
    const uploadPostAdminHome = async (isPost) => {
        console.log(selectedEnterprise , "----")
        if (isUploadText !== '' || isPost === true) {
            setIsImageUpload(false);
            setIsAudioUpload(false);
            setIsFileUpload(false);
            let scndSpecialityList = getSpecialityList(secondarySpeciality);
            let primSpecialityList = getSpecialityList(primarySpeciality);
            let universittyList = getSpecialityList(selectUnivercityList);
            let userList = getUserListtype(userType);
            if(userType==='practitioner'){
                userList = getPractionerType(practitionerType , userList);
            }
            let province_city_arr = []
            let district_arr = []
            provinceCity.forEach((item) => {
                province_city_arr.push(item.value)
            })
            district.forEach((item) => {
                district_arr.push(item.value)
            })

            let place_of_work_arr = []
            placeOfWork.forEach((item) => place_of_work_arr.push(item.value))
            var data = {
                post_type: 'public',
                is_create: true,
                text: isUploadText === '' ? null : isUploadText,
                media: mideaList,
                is_sponsored: selectedEnterprise.length > 0 ? true : false,
                is_important:isPostValue === 'Important' ? true : false,
                is_pinned : isPostValue === 'Pinned' ? true : false,
                primary_speciality: primSpecialityList,
                secondary_speciality: scndSpecialityList,
                province_city: province_city_arr,
                district: district_arr,
                age: age,
                place_of_work: place_of_work_arr,
                category_id: selectedAttribute ? selectedAttribute.category_id : null,
                user_type: userList,
                universities: universittyList,
                tag_users: selectedEnterprise
            };
            let res = await dispatch(apiCall(data, '/user/post'));
            
            if (res.data.message.MSG_CODE === 100091) {
                setIsImageUpload(false);
                setIsAudioUpload(false);
                setIsFileUpload(false);
                emptyFilterData();
                empty();
                getFeedlData(1);
                setMideaList([])
                document.getElementById('uploadImageInputDiv').value = ''
                document.getElementById('uploadAudioInputDiv').value = ''
                document.getElementById('uploadFileInputDiv').value = ''
                document.getElementById('postTextInputBox').value = ''
                document.getElementById('postTextInputBox').style.height = '3rem'
                Swal.fire({
                    icon: 'success',
                    text: 'Post updated successfully',
                    timer: 3000
                })
                setIsPostValue('General')
                setSelectedAttribute({})
            } else if (res.data.message.MSG_CODE === 100160 || res.data.message.MSG_CODE === 100010) {
                setIsImageUpload(false);
                setIsAudioUpload(false);
                setIsFileUpload(false);
                emptyFilterData();
                empty();
                document.getElementById('uploadImageInputDiv').value = ''
                document.getElementById('uploadAudioInputDiv').value = ''
                document.getElementById('uploadFileInputDiv').value = ''
                document.getElementById('postTextInputBox').value = ''
                document.getElementById('postTextInputBox').style.height = '3rem'
                Swal.fire({
                    icon: 'error',
                    text: 'Post Update Failure',
                    timer: 3000
                })
            } else if (res.data.message.MSG_CODE === 101140) {   
                Swal.fire({
                    icon: 'error',
                    text: 'You have reached the maximum number of pinned posts. Please unpin one of your existing posts to pin this new one.',
                    timer: 10000
                })
        } }  else if (isUploadText === '' && isPost === undefined) {
            Swal.fire({
                text: "Do you still want to continue with this post without content?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            }).then((result) => {
                if (result.isConfirmed === true && mideaList.length > 0 || isUploadText !== '') {
                    uploadPostAdminHome(true)
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: 'Please write some text in textbox or upload some media',
                        timer: 3000
                    })
                }
            })
        }
    }
    //upload data
    const uploadFile = async (file, type) => {
        if (isImageUpload === true && type === 'image') {
            mideaList.pop();
        } else if (isAudioUpload === true && type === 'audio') {
            mideaList.pop();
        } else if (isFileUpload === true && type === 'file') {
            mideaList.pop();
        }
        let arrayMediaId = [];
        let isUploadingCompletedSuccessfully = false
        for (let i = 0; i < file.length; i++) {
            if (file[i].size > 15097152) {
                Swal.fire({
                    text: "Upload fle with size less than 15MB",
                    toast: true,
                    position: "top-end",
                    type: "error",
                    showConfirmButton: false,
                    timer: 5000,
                });
                return
            }
            let convertString = await convertBase64(file[i]);
            let base64Array = convertString.split(",");
            let base64 = base64Array[1];
            var data = {
                email: email,
                file_path: "post",
                file_data: base64,
                file_name: file[i].name,
                width: file[i].width ? file[i].width : null,
                height: file[i].height ? file[i].height : null
            };
            let res = await dispatch(apiCall(data, '/user/upload'));
            if (res.data.message.MSG_CODE === 100231) {
                arrayMediaId.push(res.data.data.media_id)
            } else if (res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
                    timer: 3000
                })
                break
            } else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010 || res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please upload again',
                    timer: 3000
                })
                break
            }
            if (i === file.length - 1) {
                isUploadingCompletedSuccessfully = true
            }
        }
        if (isUploadingCompletedSuccessfully === true) {
            if (type === 'image') {
                setIsImageUpload(true)
            } else if (type === 'audio') {
                setIsAudioUpload(true)
            } else if (type === 'file') {
                setIsFileUpload(true);
            }
            setMideaList([...mideaList, ...arrayMediaId]);
        }
    }
    // upload video in chunks
    const fileUpload = (counter) => {
        setCounter(counter + 1);
        if (counter <= chunkCount) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk)
        }
    }
    const uploadChunk = async (chunk) => {
        try {
            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('chunk_write', 1);
            formData.append('filename', fileToBeUpload.name);
            formData.append('path', 'channel');
            formData.append('chunk_index', counter);
            formData.append('total_chunks', chunkCount);
            formData.append('media_id', mediaId);
            let res = await dispatch(chunkUpload(formData));
            if (res.data.message.MSG_CODE === 100581) {
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                setMediaId(res.data.data.media_id)
                if (counter === chunkCount) {
                    await uploadCompleted(res.data.data.media_id);
                } else {
                    var percentage = (counter / chunkCount) * 100;
                    setProgress(percentage);
                }
            } else {
                empty();
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please try again!',
                    timer: 4000
                })
            }

        } catch (error) {
            console.log('error', error)
        }
    }
    const resetChunkProperties = () => {
        setShowProgress(true)
        setProgress(0)
        setCounter(1)
        setBeginingOfTheChunk(0)
        setEndOfTheChunk(chunkSize)
    }
    const getFileContext = (e) => {
        resetChunkProperties();
        const _file = e.target.files[0];
        setFileSize(_file.size)
        const _totalCount = _file.size % chunkSize === 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount)
        setFileToBeUpload(_file)
        e.target.value = null;
    }
    const attributeSelected = (item) => {
        if (selectedAttribute === item) {
            setSelectedAttribute({})
        } else {
            setSelectedAttribute(item)
        }
    }
    const postValueSelected = (item) => {
            setIsPostValue(item)
    }
const uploadCompleted = async (val) => {
        let media_id = val;
        const formData = new FormData();    
        formData.append('file_upload', 1);
        formData.append('filename', fileToBeUpload.name);
        formData.append('media_id', mediaId === null ? media_id : mediaId);
        const res = await dispatch(chunkUpload(formData));
        if (res.data.message.MSG_CODE === 100591) {
            checkUploadingStatus(media_id)
        }
    }
    const checkUploadingStatus = async (val) => {
        let media_id = val;
        const formData = new FormData();
        formData.append('get_media_details', 1);
        formData.append('filename', fileToBeUpload.name);
        formData.append('media_id', mediaId === null ? media_id : mediaId);
        const res = await dispatch(chunkUpload(formData));

        if (res.data.message.MSG_CODE === 100601) {
            if (res.data.data.upload_status === 'IN PROGRESS') {
                setTimeout(() => {
                    checkUploadingStatus(media_id)
                }, "3000")
            } else if (res.data.data.upload_status === 'SUCCESS') {
                setProgress(100);
                if (mediaId === null) {
                    setMideaList([...mideaList, media_id]);
                } else {
                    setMideaList([...mideaList, mediaId]);
                }
            } else if (res.data.data.upload_status === 'FAILURE') {
                setShowProgress(false)
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please try again!',
                    timer: 4000
                })
            }
        }
    }
    // base64 conversion
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (() => {
                resolve(fileReader.result);
            });
            fileReader.onerror = ((error) => {
                reject(error)
            });
        });
    };
    // delete post 
    const deletePostinAdminPost = async (deletePost) => {
        var data = {
            post_id: deletePost.postID,
            is_delete: true,
        };
        let res = await dispatch(apiCall(data, '/user/post'));
        emptyFilterData();
        empty();
        if (res.data.message.MSG_CODE === 100321) {
            getFeedlData(1);
            setPage(1);
            setMideaList([])
            setIsDeletePost({ postID: '', indexOfPost: '' })
        } else if (res.data.message.MSG_CODE === 100390 || res.data.message.MSG_CODE === 100170) {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    //get all data 
    const getRegistrationPageData = async () => {
        var data = {
            preferred_language: 'EN',
        };
        let res = await dispatch(apiCall(data, '/user/get-registration-page-info'));
        if (res.data.message.MSG_CODE === 100281) {
            console.log("reg page data" , res.data.data)
            setRegistrationPageData(res.data.data)
            let newSpecArray = [];
            for (let i = 0; i < res.data.data.specialities.length; i++) {
                newSpecArray.push({ 'label': res.data.data.specialities[i].label_name, 'value': res.data.data.specialities[i].identifier_id })
            }
            setSpecialitiesList(newSpecArray);

            let newUniArray = [];
            for (let i = 0; i < res.data.data.universities.length; i++) {
                newUniArray.push({ 'label': res.data.data.universities[i].label_name, 'value': res.data.data.universities[i].identifier_id })
            }
            setUnivercityList(newUniArray);
            let newarray = res.data.data.provinces;
            newarray = Object.keys(newarray);

            let provinceArray = []
            for (let i = 0; i < newarray.length; i++) {
                provinceArray.push({ 'label': newarray[i], 'value': newarray[i] })
            }
            setProvinceData(provinceArray);

            let worplaceArray = []
            res.data.data.workplaces.forEach(element => {
                worplaceArray.push({'label': element, 'value': element})    
            });
            setWorkPlaceMaster(worplaceArray)

        } else {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }

    //clear filter data
    const emptyFilterData = () => {
        setPrimarySpeciality([]);
        setSecondarySpeciality([]);
        setProvinceCity([]);
        setDistrict([]);
        setAge([]);
        setPlaceOfWork([]);
        setMinValue('');
        setMaxValue('');
        setSelectUnivercityList([]);
    }
    // add provice city to city list 
    const onProvinceClick = (val) => {
        setProvinceCity(val)
        let newdata = []
        
        val.forEach((value) => {
            registrationPageData.provinces[value.value].forEach((district) => {
                newdata.push({ 'label': district, 'value': district })
            })
        })
        const uniqueCity = [...new Set(newdata)]
        setProvinceCityData(uniqueCity)
        setDistrict(uniqueCity)

      
    }
    const onEnterpriseClick = (val) => {
        setSelectedEnterprise(val)
    }
    // save filter data
    const saveAllFilterData = () => {
        console.log()
        if (minValue > 1 && maxValue > 1) {
            let ageArray = [];
            if (minValue >= 18 && maxValue < 100) {
                ageArray.push(parseInt(minValue))
                ageArray.push(parseInt(maxValue))
                setAge(ageArray)
                setModalShow(false)
            } else {
                Swal.fire({
                    icon: 'error',
                    text: 'Please enter age between 18 to 100',
                    timer: 3000
                })
            }
        } else {
            setModalShow(false)
        }
    }
    //model operation
    const closeFilterModel = () => {
        setModalShow(false)
        emptyFilterData();
        setProvinceCityData([]);
    }
    const getAttributesData = async (pg) => {
        var data = {
            is_get: true,
            content_language: 'EN',
            per_page: 10,
            page_number: pg
        };
        let res = await dispatch(apiCall(data, '/user/admin-category'));
        if (res.data.message.MSG_CODE === 100661) {
            let newUniArray = [];
            let newPostAttributs = [];
            for (let i = 0; i < res.data.data.length; i++) {
                if(res.data.data[i].type == "CATEGORY"){
                newUniArray.push({ 'label': res.data.data[i].category_title, 'value': res.data.data[i].category_id })
                newPostAttributs.push(res.data.data[i])
                }
            }
            setPostAttribute(newPostAttributs);
            setFilterAttribute(newUniArray);
        }
        else if(res.data.message.MSG_CODE === 100130){
            console.log("Enterprise User Logged in")
        }
        else {
            console.log("Error message for admin catagory")
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    // set user type 
    const setUserTypeData = (val) => {
        emptyFilterData();
        setUserType(val)
    }
    // get admin post data
    const getAdminPostData = async (id) => {
        var data = {
            get_specific_post_data: true,
            post_id: id,
            content_language: 'EN',
            per_page: 10,
            page_number: 1
        };
        let res = await dispatch(apiCall(data, '/user/admin-feed'));
        if (res.data.message.MSG_CODE === 100621) {
            setShowAdminPostModal(true)
            setIsShowAdminPost('')
            setAdminPostData(res.data.data[0].filters);

        }
        else {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    // search post channel admin 
    const searchAdminData = async (pg, searchValue) => {
        var data = {
            get_all: true,
            search_data: searchValue,
            per_page: 7,
            preferred_language: 'EN',
            page_number: pg
        }
        let res = await dispatch(apiCall(data, '/user/search-all', true));
        if (isCallData === true) {
            dispatch({ type: "clearCallChannelData" });
        }
        if (res.data.message.MSG_CODE === 100891) {
            if (searchPost.trim().length === 0) {
                dispatch({ type: "clearFeedData" });
            } else {
                dispatch({ type: "getFeedData", ...res.data });
                dispatch({ type: "getChannelFeedData", ...res.data });
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                text: 'Something went, please try again later',
                timer: 3000
            })
        }
    }
    //upload image
    const handleSingleImageUpload = async (file) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
      
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            // Release the object URL to free up memory
            URL.revokeObjectURL(img.src);
            // Resolve the promise with width and height
            resolve({ width, height });
          };
        });
      };
      
    const uploadImage = async (files, type) => {
        
        dispatch({ type: "showLoader"});
        if (isImageUpload === true && type === 'image') {
            mideaList.pop();
        }
        let arrayFile = []
        for (let i = 0; i < files.length; i++) {
            if ( files[i].type === 'image/heif' || files[i].type === 'image/HEIF') {
                arrayFile.push(files[i]);
            }else{
                const image = await compressionImage(files[i]);
                let diamentions = await handleSingleImageUpload(files[i]);
                const height = diamentions.height
                const width = diamentions.width
                image.height = height
                image.width = width
                arrayFile.push(image);  
            }

        }
        dispatch({ type: "hideLoader"});
        uploadFile(arrayFile, type);
    };
    // search post channel admin 
    return (
        <>
            <section>
                <Row style={{ height: '100vh' }}>
                    <Col className='' xxl={2} xl={2} lg={3} md={3} style={{ background: 'linear-gradient(90deg, #277AFC 0%, #27B3FC 100%)', height: '100%' }} >
                        <LeftSideBar />
                    </Col>
                    <Col className='bg-prime p-0 adminPannel' xxl={10} xl={10} lg={9} md={9}>
                        <Container className='log-wrap p-0' fluid style={{ height: '100vh' }}>
                            <React.Fragment>
                                <>
                                    <section className='px-3 py-3' style={{ height: '100%' }}>
                                        <div>
                                            <h5>Home</h5>
                                            <hr></hr>
                                        </div>
                                        <Row className="justify-content-md-center postPageData">
                                            <Col xxl={10} xl={10} lg={10} md={10} className='p-0' style={{ height: '100%' }}>
                                                <div className='adminPostDiv' id="scrollableDiv" >
                                                    {permissions.includes('Publish') && <div className='uploadAdminPostDiv mt-1 bg-white py-2 d-flex filter-search-div' >
                                                        <div className='searchAdminPost d-flex search-div'>
                                                            <InputGroup className="w-100">
                                                                <Form.Control
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                    value={searchPost}
                                                                    type='text'
                                                                    onChange={(e) => setSearchPost(e.target.value)}
                                                                    style={{ cursor: 'auto !important', paddingRight: '1.6rem' }}
                                                                />
                                                            </InputGroup>
                                                            {
                                                                searchPost.trim().length === 0 ?
                                                                    <span role='button' className='searchIcon' onClick={() => { }}><BsIcons.BsSearch /></span> :
                                                                    <span role='button' className='searchIcon' onClick={() => { setSearchPost('') }}><AsIcons.AiOutlineClose /></span>
                                                            }
                                                        </div>
                                                        <div className='filter-div d-flex' style={{}} >
                                                            <h6>Filter posts:  </h6>
                                                            {
                                                                filterAttribute !== undefined ?
                                                                    <>
                                                                        <MultiSelect
                                                                            options={filterAttribute}
                                                                            value={selectedFilterAttribute}
                                                                            onChange={setSelectedFilterAttribute}
                                                                            labelledBy="Select"
                                                                            disabled={isSearchActive}
                                                                            className='custom-multiselect-class'
                                                                        />
                                                                    </> : null

                                                            }
                                                        </div>
                                                    </div>}
                                                    {permissions.includes('Publish') && searchPost.length === 0 && <div className='uploadAdminPostDiv mt-1 bg-white pt-3'>
                                                        <div className='profileImagePicDiv d-flex'>
                                                            <span className='w-25'>
                                                                {
                                                                    profileUrl.profile_picture !== null ?
                                                                    <img className='profileImagePic' src={profileUrl.profile_picture} alt='profileImagePic' />:
                                                                    <img className='profileImagePic' src={image.porfilePicture} alt='profileImagePic' />
                                                                }
                                                            </span>
                                                            <textarea
                                                                name="searchValue" className='postInputBox'
                                                                id='postTextInputBox'
                                                                rows="2" cols="200"
                                                                placeholder="What's happening"
                                                                value={isUploadText}
                                                                onChange={(e) => setIsUploadText(e.target.value)}
                                                                onKeyDown={changeHeightTextArea('#postTextInputBox')}
                                                            >
                                                            </textarea>
                                                        </div>
                                                        <div className='uploadAdminPostInputDiv'>
                                                            <div className='py-2 d-flex align-items-center justify-content-center uploadAdminPostInputDivRow' xxl={12} xl={12} lg={12} md={4}>
                                                                <div className='d-flex justify-content-center AdminUploadOptionDiv' style={{ background: isImageUpload === true ? '#389ffe5c' : '' }} >
                                                                    <img className='mx-2' src={image.galleryImage} style={{ width: '1.4em' }} alt='galleryImage' />
                                                                    <input type="file" id="uploadImageInputDiv" name="avatar" accept=" image/png, image/jpeg, image/webp, image/gif, .HEIC, .heic" multiple onChange={(event) => uploadImage(event.target.files, 'image')} />
                                                                    <span className='uploadOptionDivName'>Photo</span>
                                                                </div>
                                                                <div className='d-flex justify-content-center AdminUploadOptionDiv' style={{ background: isAudioUpload === true ? '#389ffe5c' : '' }}>
                                                                    <img className='mx-2' src={image.audioImage} alt='audioImage' style={{ width: '1.4em' }} />
                                                                    <input type="file" id="uploadAudioInputDiv" name="avatar" accept="audio/mp3, audio/mpeg,audio/mpg" onChange={(event) => uploadFile(event.target.files, 'audio')} />
                                                                    <span className='uploadOptionDivName'>Audio</span>
                                                                </div>
                                                                <div className='d-flex justify-content-center AdminUploadOptionDiv' style={{ background: isFileUpload === true ? '#389ffe5c' : '' }}>
                                                                    <img className='mx-2' src={image.attachmentIconImage} alt='audioImage' style={{ width: '1.4em' }} />
                                                                    <input type="file" id="uploadFileInputDiv" name="avatar" accept="application/pdf, application/vnd.ms-excel" onChange={(event) => uploadFile(event.target.files, 'file')} />
                                                                    <span className='uploadOptionDivName'>File</span>
                                                                </div>
                                                                <div className='p-0 AdminUploadOptionVideoMainDiv '>
                                                                    <Form className='AdminUploadOptionVideoDiv'>
                                                                        <Form.Group style={{ display: showProgress ? "none" : "flex" }} controlId="" className="adminUploadVideoForm">
                                                                            <Form.Control type="file" id="" style={{ padding: "0px" }} size="sm" accept="video/mp4" onChange={(e) => { getFileContext(e) }} />
                                                                            <img className='mx-2' src={image.videoImage} alt='videoImage' />
                                                                            <Form.Label className="uploadOptionDivName mb-0">Video</Form.Label>
                                                                        </Form.Group>
                                                                        <Form.Group style={{ display: showProgress ? "block" : "none", height: '100%', borderRadius: '1rem' }}>
                                                                            {progressInstance}
                                                                        </Form.Group>
                                                                    </Form>
                                                                </div>
                                                                <div className='sponsoredBtn d-flex px-3 py-0' style={{ width: '100%' }} >
                                                                    <DropdownButton id="dropdown-basic-button px-1 " title={isPostValue.length > 10 ? isPostValue.slice(0, 10) + '...' : isPostValue}>
                                                                        {
                                                                            postValues && postValues.length > 0 && postValues.map((item, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <Dropdown.Item className={isPostValue === item ? 'active' : ''} onClick={() => postValueSelected(item)} >{item}</Dropdown.Item>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </DropdownButton>
                                                                    <DropdownButton id="dropdown-basic-button px-1" title={JSON.stringify(selectedAttribute) === '{}' ? "Attributes" : selectedAttribute.category_title.length > 10 ? selectedAttribute.category_title.slice(0, 10) + '...' : selectedAttribute.category_title}>
                                                                        {/* <Dropdown.Item onClick={() => setSelectedAttribute({})} >Attributes</Dropdown.Item> */}
                                                                        {postAttribute && postAttribute.length > 0 && postAttribute.map((item, index) => {
                                                                            return (
                                                                                <div key={index}>
                                                                                    <Dropdown.Item className={selectedAttribute === item ? 'active' : ''} onClick={() => attributeSelected(item)} >{item.category_title}</Dropdown.Item>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </DropdownButton>
                                                                    <Button className='adminPostFIlterButton' onClick={() => setModalShow(true)}>Filter</Button>

                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>

                                                                <h6 style={{ marginRight: '1rem' }}>Tag an enterprise user</h6>
                                                                <Switch onChange={toggleList} checked={showEnterpriseList} onColor="#86d3ff"
                                                                    onHandleColor="#2693e6"
                                                                    handleDiameter={20}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
                                                                    height={15}
                                                                    width={36}
                                                                    className="react-switch"
                                                                    id="material-switch" />
                                                            </div>

                                                            {enterpriseUsersList.length > 0 && showEnterpriseList &&
                                                                <MultiSelect
                                                                    options={enterpriseUsersList}
                                                                    value={selectedEnterprise}
                                                                    onChange={setSelectedEnterprise}
                                                                    hasSelectAll={false}
                                                                    labelledBy="label"

                                                                />}
                                                            <div xxl={12} xl={3} lg={3} md={12} className='text-end adminPostuploadButtonDiv' style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                                                <Button className='adminPostuploadButton' style={{ width: 'fit-content', marginBottom: '1rem' }} onClick={() => uploadPostAdminHome()}>Post</Button>

                                                            </div>
                                                        </div>
                                                    </div>}
                                                    <div>

                                                    </div>
                                                    {
                                                        isSearchActive === false ?
                                                            <InfiniteScroll
                                                                dataLength={feedData.length}
                                                                next={getNewFeedData}
                                                                hasMore={isMoreData}
                                                                scrollableTarget="scrollableDiv"
                                                            >
                                                                <div className='adminFeedData'>
                                                                    {
                                                                        feedData.map((feed, index3) => {
                                                                            return (
                                                                                <div key={index3}  >
                                                                                    <MediaGrid feedData={feed} deletePost={setIsDeletePost} indexOfFeed={index3} sharePost={setIsSharePost} showAdminPost={setIsShowAdminPost} searchedValue={searchPost}
                                                                                        permissions={permissions} isEditPost={setIsEditPost} isAttributeSelected={selectedFilterAttribute.length > 0 ? true : false} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </InfiniteScroll> :
                                                            <SearchUserFeed feedData={searchPostData} permissions={permissions} searchedValue={searchPost} />
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </section>
                                </>
                            </React.Fragment>
                        </Container>
                    </Col>
                </Row>
            </section>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Filter
                    </Modal.Title>
                    <CloseButton onClick={() => { closeFilterModel() }} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div>
                                    <div className='my-2'>
                                        <h6>User Type <span className="redStar">&#42;</span> </h6>
                                        <div className='d-flex'>
                                        <input type="radio" className="" checked={userType === ''} name='userType' value={''} style={{ cursor: 'pointer' }} onChange={e => setUserTypeData(e.target.value)} />
                                            <label className="mx-2" >None</label><br></br>
                                            <input type="radio" className="" checked={userType === 'student'} name='userType' value={'student'} style={{ cursor: 'pointer' }} onChange={e => setUserTypeData(e.target.value)} />
                                            <label className="mx-2" >Student</label><br></br>
                                            <input className="mx-2" type="radio" checked={userType === 'practitioner'} name='userType' value={'practitioner'} style={{ cursor: 'pointer' }} onChange={e => setUserTypeData(e.target.value)} />
                                            <label className="mx-2" >Practitioner</label><br></br>
                                            <input className="mx-2" type="radio" checked={userType === 'both'} name='userType' value={'both'} style={{ cursor: 'pointer' }} onChange={e => setUserTypeData(e.target.value)} />
                                            <label className="mx-2" >Both</label><br></br>
                                        </div>
                                    </div>
                                    {
                                        userType === 'student' || userType === 'both' ?
                                            <div className='my-1'>
                                                <h6>University </h6>
                                                <MultiSelect
                                                    options={univercityList}
                                                    value={selectUnivercityList}
                                                    onChange={setSelectUnivercityList}
                                                    labelledBy="Select"
                                                />
                                            </div> : null
                                    }
                                    {
                                        userType === 'practitioner' ?
                                            <div className='my-1'>
                                                <h6>Practitioner </h6>
                                                <div className='d-flex'>
                                                    <input type="radio" className="" checked={practitionerType === 'surgeon'} name='practitioner' value={'surgeon'} style={{ cursor: 'pointer' }} onChange={e => setPractitionerType(e.target.value)} />
                                                    <label className="mx-2" >Surgeon</label><br></br>
                                                    <input className="mx-2" type="radio" checked={practitionerType === 'physician'} name='practitioner' value={'physician'} style={{ cursor: 'pointer' }} onChange={e => setPractitionerType(e.target.value)} />
                                                    <label className="mx-2" >Physician</label><br></br>
                                                    <input className="mx-2" type="radio" checked={practitionerType === 'both'} name='practitioner' value={'both'} style={{ cursor: 'pointer' }} onChange={e => setPractitionerType(e.target.value)} />
                                                    <label className="mx-2" >Both</label><br></br>
                                                </div>
                                            </div> : null
                                    }
                                    <div className='my-2' style={{ position: 'relative' }}>
                                        <h6>Province/City </h6>

                                        {
                                            provinceData.length > 0 ?
                                                <MultiSelect
                                                    options={provinceData}
                                                    onChange={(e) => onProvinceClick(e)}
                                                    value={provinceCity}
                                                /> : <select className="form-control selectpicker searchWrapper" id="select-country" data-live-search="true">
                                                    <option key='noCity'>select city</option>
                                                </select>

                                        }

                                    </div>
                                    <div className='my-2' style={{ position: 'relative' }}>
                                        <h6>District </h6>
                                        {
                                            provinceCityData.length > 0 ?
                                                <MultiSelect
                                                    isObject={false}
                                                    options={provinceCityData}
                                                    onChange={setDistrict}
                                                   value={district}
                                                /> : <select className="form-control selectpicker" id="select-country" data-live-search="true">
                                                    <option key='noCity'>select District</option>
                                                </select>

                                        }
                                    </div>
                                    <div className='my-2'>
                                        <h6>Primary Specialty  </h6>
                                        {
                                            registrationPageData.specialities !== undefined ?
                                                <>
                                                    <MultiSelect
                                                        options={specialitiesList}
                                                        value={primarySpeciality}
                                                        onChange={setPrimarySpeciality}
                                                        labelledBy="Select"
                                                    />
                                                </> : null

                                        }
                                    </div>
                                    <div className='my-2'>
                                        <h6>Secondary Specialty  </h6>
                                        {
                                            registrationPageData.specialities !== undefined ?
                                                <>
                                                    <MultiSelect
                                                        options={specialitiesList}
                                                        value={secondarySpeciality}
                                                        onChange={setSecondarySpeciality}
                                                        labelledBy="Select"
                                                    />
                                                </> : null
                                        }
                                    </div>
                                    {
                                        userType !== 'student' ?
                                            <div className='my-2'>
                                                <h6>Workplaces  </h6>
                                                {
                                                    workPlacesMaster !== undefined ?
                                                        <MultiSelect
                                                            
                                                            value={placeOfWork}
                                                            options={workPlacesMaster}
                                                            onChange={setPlaceOfWork}
                                                            labelledBy="Select"
                                                        /> : null
                                                }
                                            </div> : null
                                    }
                                    <div>
                                        <h6>Age  </h6>
                                        <div className='d-flex'>
                                            <input type="number" className="form-control" placeholder="Min age"
                                                aria-label="Title" aria-describedby="basic-addon1"
                                                value={minValue}
                                                onChange={(e) => setMinValue(e.target.value)}
                                            />
                                            <input type="number" className="form-control" placeholder="Max age"
                                                aria-label="Title" aria-describedby="basic-addon1"
                                                value={maxValue}
                                                onChange={(e) => setMaxValue(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { saveAllFilterData() }}>Done</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAdminPostModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Filter Data
                    </Modal.Title>
                    <CloseButton onClick={() => { setShowAdminPostModal(false) }} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div>

                                    <div className='my-2 '>
                                        {
                                            adminPostData.user_type !== undefined && adminPostData.user_type.length > 0 ?
                                                <div>
                                                    <h6>User Type:-</h6>
                                                    <div className='d-flex filterDataDiv'>
                                                    {
                                                         adminPostData.user_type && adminPostData.user_type[0]==='STUDENT' && adminPostData.user_type.map((user, ind) => {
                                                            return (
                                                                <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={ind}>
                                                                    {user}
                                                                </Badge>
                                                            )
                                                        })
                                                    }
                                                     {
                                                         adminPostData.user_type && adminPostData.user_type[0]==='DOCTOR' && adminPostData.user_type.length===1 && adminPostData.user_type.map((user, ind) => {
                                                            return (
                                                                <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={ind}>
                                                                    {user}
                                                                </Badge>
                                                            )
                                                        })
                                                    }   
                                                     {
                                                         adminPostData.user_type && adminPostData.user_type[0]==='DOCTOR' && adminPostData.user_type.length===2 && 
                                                           
                                                                <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge'
                                                                >
                                                                    {adminPostData.user_type[1]}
                                                                </Badge>
                                                            
                                                    }   

                                                    </div>
                                                </div>
                                                : adminPostData?.user_type?.length === 0 ? <div> 
                                                 <h6>User Type:-</h6>
                                                 <div className='d-flex filterDataDiv'>
                                                 <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge'>
                                                                    None
                                                                </Badge>
                                                    </div>
                                                    </div>
                                             : null
                                        }

                                    </div>
                                    {
                                        adminPostData.age !== undefined && adminPostData.age.length > 1 ?
                                            <div className='my-2'>
                                                <h6>Age:-</h6>
                                                <div className='d-flex filterDataDiv'>
                                                    <span>Minimum Age:-{adminPostData.age[0]}  Maximum Age:-{adminPostData.age[1]}</span>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        adminPostData.district !== undefined && adminPostData.district.length > 0 ?
                                            <div className='my-2'>
                                                <h6>District:-</h6>
                                                <div className='d-flex filterDataDiv'>
                                                    {
                                                        adminPostData.district.map((dist, ind) => {
                                                            return (
                                                                <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={ind}>
                                                                    {dist}
                                                                </Badge>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        adminPostData.place_of_work !== undefined && adminPostData.place_of_work.length > 0 ?
                                            <div className='my-2'>
                                                <h6>Place of work:-</h6>
                                                <div className='d-flex filterDataDiv'>
                                                    {
                                                        adminPostData.place_of_work.map((place, ind) => {
                                                            return (
                                                                <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={ind}>
                                                                    {place}
                                                                </Badge>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        adminPostData.primary_speciality !== undefined && adminPostData.primary_speciality.length > 0 ?
                                            <div className='my-2'>
                                                <h6>Primary speciality:-</h6>
                                                <div className='d-flex filterDataDiv'>
                                                    {
                                                        adminPostData.primary_speciality.map((prSp, ind) => {
                                                            return (
                                                                <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={ind}>
                                                                    {prSp.label_name}
                                                                </Badge>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        adminPostData.secondary_speciality !== undefined && adminPostData.secondary_speciality.length > 0 ?
                                            <div className='my-2'>
                                                <h6>Secondary speciality:- </h6>
                                                <div className='d-flex filterDataDiv'>
                                                    {
                                                        adminPostData.secondary_speciality.map((secSp, ind) => {
                                                            return (
                                                                <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={ind}>
                                                                    {secSp.label_name}
                                                                </Badge>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        adminPostData.province_city !== undefined && adminPostData.province_city.length > 0 ?
                                            <div className='my-2'>
                                                <h6>Province city:-</h6>
                                                <div className='d-flex filterDataDiv'>
                                                    {
                                                        adminPostData.province_city.map((city, ind) => {
                                                            return (
                                                                <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={ind}>
                                                                    {city}
                                                                </Badge>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        adminPostData.universities !== undefined && adminPostData.universities.length > 0 ?
                                            <div className='my-2'>
                                                <h6>Universities:- </h6>
                                                <div className='d-flex filterDataDiv '>
                                                    {
                                                        adminPostData.universities.map((uni, ind) => {
                                                            return (
                                                                <Badge align="center" bg="primary" className='mx-2 my-1 py-2 roleBadge' key={ind}>
                                                                    {uni.label_name}
                                                                </Badge>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> : null
                                    }
                                    
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setShowAdminPostModal(false)() }}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdminPost